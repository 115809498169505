export default () => {
    addEventListener('message', e => {
        if (!e) {
            return;
        } else {
            // worker to start the logging processes
            if (e.data === 'start') {
                setInterval(() => {
                    postMessage('location.logging');
                }, 1800000);
                //gps_status - worker to check if the gps has been enabled
            } else if (e.data === 'gps_status') {
                setInterval(() => {
                    postMessage('location.request');
                }, 120000)
            }
        }
    });
}