import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import palette from './palette';

const theme = responsiveFontSizes(
  createTheme({
    palette,
    layout: {
      contentWidth: 1180,
    },
    typography: {
      fontFamily: 'Poppins !important',
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: 'white',
          fontFamily: 'Poppins'
        },
      },
    },
    shadows: Array(25).fill('none')
  }),
);

export default theme;
