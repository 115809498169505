import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControlLabel, Button, MenuItem, Radio, RadioGroup, FormControl, FormLabel, TextField } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axiosInstance from 'services/axiosInstance';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment'
import { base54Decode, base54Encode } from 'helpers/base54Utils';
import { Box } from '@mui/material';
import Loader from 'components/Loader/Loader';

const relationships = ['Husband', 'Wife', 'Child (son/daughter)', 'Grandparents', 'Brother', 'Sister', 'Parent']

function createDurationList() {
    const durationList = [{ tkey: 'Less than a year', answer: 'Less than a year' }];
    for (let i = 1; i <= 20; i++) {
        if (i === 1) {
            durationList.push({ tkey: '1 year', answer: '1 year' });
        } else {
            durationList.push({ tkey: `${i} years`, answer: `${i} years` });
        }
    }
    return durationList;
}

function numberOfHouseHoldMembers() {
    const durationList = [];
    for (let i = 1; i <= 20; i++) {
        durationList.push({ tkey: `${i}`, answer: `${i}` });
    }
    return durationList;
}

const data = [{
    answer_type: 'dropdown',
    question: 'Using period of a phone number',
    tkey: 'phoneDuration',
    is_required: true,
    helperText: '',
    answers: createDurationList()
}, {
    answer_type: 'dropdown',
    question: 'County of residence',
    tkey: 'county',
    is_required: true,
    helperText: '',
    answers: []
}, {
    answer_type: 'dropdown',
    question: 'How long does the client live there?',
    tkey: 'location',
    is_required: true,
    helperText: '',
    answers: createDurationList()
}, {
    answer_type: 'number',
    question: 'National ID Number',
    tkey: 'nationalIdNumber',
    is_required: true,
    helperText: ''
}, {

    answer_type: 'radio',
    question: 'Does the client own the land?',
    tkey: 'isLandOwner',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'Yes',
        answer: 'Yes'
    }, {
        tkey: 'No',
        answer: 'No'
    }]
}, {
    answer_type: 'dropdown',
    question: 'How long has the client farmed?',
    tkey: 'farmerDuration',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: '0 - 1 year',
        answer: '0 - 1 year'
    }, {
        tkey: '1 - 2 years',
        answer: '1 - 2 years'
    }, {
        tkey: '2 - 5 years',
        answer: '2 - 5 years'
    }, {
        tkey: 'More than 5 years',
        answer: 'More than 5 years'
    }]
}, {
    answer_type: 'dropdown',
    question: 'Number of Household Members?',
    tkey: 'numberOfHouseHoldMembers',
    is_required: true,
    helperText: '',
    answers: numberOfHouseHoldMembers()
}, {
    answer_type: 'date',
    question: 'Date of birth',
    tkey: 'dateOfBirth',
    is_required: true,
    future: true,
    past: false,
    helperText: ''
}, {
    answer_type: 'date',
    question: 'When ready to make the deposit?',
    tkey: 'depositDate',
    is_required: true,
    future: false,
    past: true,
    helperText: ''
}, {
    answer_type: 'dropdown',
    question: 'Gender',
    tkey: 'gender',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'Male',
        answer: 'Male'
    }, {
        tkey: 'Female',
        answer: 'Female'
    }]
}]

const CDS = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [cdsid, setCDSId] = useState(null)
    const [locals, setLocals] = useState({ agentId: "", leadId: "", accountRef: "", mobilePhone: "" })
    const [formValues, setFormValues] = useState({});
    const [nextOfKin, setNextOfKin] = useState([{ relation: '', nextKinName: '', nextKinSurname: '', nextKinPhoneNumber: '' }]);
    const [idCount, setIdCount] = useState(1);
    const location = useLocation();
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params = queryParams.get('params')
        const decodedText = base54Decode(params)
        const keyValuePairs = decodedText.split("&&");
        const values = {};
        keyValuePairs.forEach(pair => {
            pair = pair.replace(/\0/g, '');
            const [key, value] = pair.split("=").map(str => str.trim());
            values[key] = value;
        });
        const leadId = values['leadId'];
        const accountRef = values['accountRef'];
        const mobilePhone = values['mobilePhone'];
        const agentId = values['agentId']
        if (accountRef !== "null") {
            setFormValues((prevValues) => ({ ...prevValues, nationalIdNumber: accountRef }));
        } else {
            setResponse({ msg: `KYC completion is required before proceeding with the CDS1 form.`, status: "error" })
        }

        setLocals({ agentId: agentId, leadId: leadId, accountRef: accountRef, mobilePhone: mobilePhone })
        fetchCounties()
        setTimeout(() => {
            fetchCDSInformation(leadId)
        }, 500)
    }, [])


    const fetchCDSInformation = async (leadId) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`cds?leadId=${leadId}`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                if (records.length > 0) {
                    const itemData = records[0]
                    Object.entries(itemData).forEach(([key, value]) => {
                        if (value !== null) {
                            if (key === 'depositDate') {
                                setFormValues((prevValues) => ({ ...prevValues, [key]: dayjs(value) }));
                            } else {
                                if (key === "id") {
                                    setCDSId(value)
                                } else {
                                    setFormValues((prevValues) => ({ ...prevValues, [key]: value }));
                                }
                            }
                        }
                    });
                }
            } else {
                setResponse({ status: 'error', msg: 'Request failed. Please try again' })
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                setResponse({ status: "error", msg: error.headers.status_message })
            } else {
                setResponse({ status: "error", msg: 'Sorry Something Went wrong Please try again' })
            }
        }
    }


    const handleInputChange = (event, tkey) => {
        const { _id, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: value }));
    }


    const handleAddNextOfKin = () => {
        setIdCount(idCount + 1);
        setNextOfKin([...nextOfKin, { id: idCount + 1, relation: '', nextKinName: '', nextKinSurname: '', nextKinPhoneNumber: '' }]);
    };


    const handleInputChange3 = (id, event) => {
        const { name, value } = event.target;
        const updatedNextOfKin = nextOfKin.map((kin) =>
            kin.id === id ? { ...kin, [name]: value } : kin
        );
        setNextOfKin(updatedNextOfKin);
    };

    const handleRemoveNextOfKin = (id) => {
        const updatedNextOfKin = nextOfKin.filter((kin) => kin.id !== id);
        setNextOfKin(updatedNextOfKin);
    };

    const handleInputChange2 = (event, tkey) => {
        setFormValues((prevValues) => {
            if (!event) {
                const { [tkey]: _, ...rest } = prevValues;
                return rest;
            }
            return { ...prevValues, [tkey]: dayjs(event) };
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        const combineJsonObject = Object.assign({}, formValues, nextOfKin[0], {
            mobilePhone: locals.mobilePhone, leadId: locals.leadId,
            lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng')
        });
        try {
            const response = await axiosInstance.post(`cds`, combineJsonObject);
            setLoading(false)
            if (response.status === 200) {
                setResponse({ msg: 'CDS 1 information has been created successfully.', status: 'success' })
                setTimeout(() => {
                    let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                    const encodedText = base54Encode(params)
                    navigate(`/view/lead-detail?params=${encodedText}`)
                }, 2000)
            } else {
                setResponse({ msg: response.data.headers.status_message, status: 'error' })
            }

        } catch (error) {
            setLoading(false)
            setResponse({ msg: error.headers.status_message, status: "error" })
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const fetchCounties = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`counties`);
            if (response.status === 200) {
                let localData = response.data.body
                let myCounties = localData.map(c => {
                    return { tkey: c.state_name, answer: c.state_name }
                })
                data[1].answers = myCounties
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setResponse({ msg: JSON.stringify(error.headers.status_message), status: "error" })
        }
    }

    const renderNextOfKinForm = () => {
        return nextOfKin.map((kin, index) => (
            <div key={index}>
                <Grid item xs={12} md={12} key={kin.id} style={{ marginTop: '8px' }}>
                    <FormControl fullWidth component="fieldset">
                        <TextField
                            fullWidth
                            id={kin.id}
                            select
                            required={true}
                            size="medium"
                            label={`Kin relationship`}
                            variant='outlined'
                            margin='normal'
                            name='relation'
                            value={kin.relation}
                            onChange={(event) => handleInputChange3(kin.id, event)}>
                            {relationships.map((option, i) => (
                                <MenuItem id={option} key={i} value={option}>{option}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={12} key={kin.id} style={{ marginTop: '8px' }}>
                    <TextField
                        margin="normal"
                        required
                        size="medium"
                        fullWidth
                        label={`Next of Kin Name`}
                        variant='outlined'
                        value={kin.nextKinName}
                        onChange={(event) => handleInputChange3(kin.id, event)}
                        name={`nextKinName`}
                        type="text"
                        id={kin.id}
                    />
                </Grid>

                <Grid item xs={12} md={12} key={kin.id} style={{ marginTop: '8px' }}>
                    <TextField
                        margin="normal"
                        required
                        size="medium"
                        fullWidth
                        label={`Next of Kin Surname`}
                        variant='outlined'
                        value={kin.nextKinSurname}
                        onChange={(event) => handleInputChange3(kin.id, event)}
                        name={`nextKinSurname`}
                        type="text"
                        id={kin.id}
                    />
                </Grid>

                <Grid item xs={12} md={12} key={kin.id} style={{ marginTop: '8px' }}>
                    <TextField
                        margin="normal"
                        required
                        size="medium"
                        fullWidth
                        label={`Phone Number`}
                        variant='outlined'
                        value={kin.nextKinPhoneNumber}
                        onChange={(event) => handleInputChange3(kin.id, event)}
                        name={`nextKinPhoneNumber`}
                        type="text"
                        id={kin.id}
                    />
                </Grid>

                <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', display: 'none' }}>
                    <Button variant="outlined" startIcon={<DeleteForeverIcon />} color='secondary' onClick={() => handleRemoveNextOfKin(kin.id)}>Remove</Button>
                </Grid>

            </div>
        ));
    };

    return (
        <div className={classes.root}>
            {loading && <Loader />}
            <div className={classes.shape}>
                <Box component="main" sx={{ p: 3, mb: 0, mt: 0, position: 'relative', width: '100%' }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        direction='row'>
                        <Grid
                            item
                            xs={4}
                            onClick={() => {
                                let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                                const encodedText = base54Encode(params)
                                navigate(`/view/lead-detail?params=${encodedText}`)
                            }}
                            md={6}
                            data-aos={'fade-up'}
                            style={{ display: 'flex', color: 'white', alignItems: 'center', flexDirection: 'row' }}>
                            <ArrowBackIosIcon className={classes.backArrow} /> <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            md={6}
                            data-aos={'fade-up'}>
                            <Typography variant='h6' style={{ color: 'white' }}>CDS</Typography>
                        </Grid>

                    </Grid>
                </Box>
            </div>
            <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>
                <Grid container spacing={0}>
                    {response.status && <Grid spacing={3} container>
                        <Grid item xs={12} md={12}>
                            <Alert severity={response.status} style={{ marginBottom: '10px', fontSize: '12px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{response.msg}</Alert>
                        </Grid>
                    </Grid>}
                    {data.map((field, index) => {
                        switch (field.answer_type) {
                            case 'text':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '0px' : '8px' }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            size="medium"
                                            fullWidth
                                            label={field.question}
                                            variant='outlined'
                                            value={formValues[field.tkey] || ''}
                                            onChange={e => handleInputChange(e, field.tkey)}
                                            name={field.tkey}
                                            type="text"
                                            helperText={field.helperText}
                                            id={field.tkey}
                                        />
                                    </Grid>
                                );
                            case 'number':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '0px' : '8px' }}>
                                        <FormControl fullWidth component="fieldset">
                                            <TextField
                                                margin="normal"
                                                required={field.is_required === true ? true : false}
                                                size="medium"
                                                fullWidth
                                                helperText={field.helperText}
                                                label={field.question}
                                                variant='outlined'
                                                disabled={field.tkey === "nationalIdNumber"}
                                                value={formValues[field.tkey] || ''}
                                                onChange={e => handleInputChange(e, field.tkey)}
                                                name={field.tkey}
                                                type="number"
                                                id={field.tkey}
                                                InputProps={{
                                                    inputProps: {
                                                        border: '1px solid green',
                                                        min: 0,
                                                        max: 100
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                );
                            case 'dropdown':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '-5px' : '8px' }}>
                                        <FormControl fullWidth component="fieldset">
                                            <TextField
                                                fullWidth
                                                id={field.tkey}
                                                select
                                                size="medium"
                                                label={field.question}
                                                variant='outlined'
                                                margin='normal'
                                                required={field.is_required === true ? true : false}
                                                helperText={field.helperText}
                                                value={formValues[field.tkey] || ''}
                                                onChange={e => handleInputChange(e, field.tkey)}>
                                                {field.answers.map((option, i) => (
                                                    <MenuItem id={option.tkey} key={i} value={option.answer}>
                                                        {option.answer}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                );
                            case 'radio':
                                return (
                                    <Grid alignItems="center" container spacing={2} mt={1} key={field.tkey} style={{ marginTop: '8px' }}>
                                        <Grid item xs={6} md={6}>
                                            <FormControl fullWidth>
                                                <FormLabel id="_animal_label" className='label1'>{field.question}<sup>*</sup></FormLabel>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <FormControl fullWidth>
                                                <RadioGroup
                                                    fullWidth
                                                    row
                                                    aria-labelledby="label_animal_01"
                                                    defaultValue="female"
                                                    name="label_animal_02">
                                                    {field.answers.map((option, i) => (
                                                        <FormControlLabel key={i} value={option.answer} control={<Radio checked={formValues[field.tkey] === option.answer} value={option.answer} onChange={e => handleInputChange(e, field.tkey)} />} label={option.answer} />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                );

                            case 'date':
                                return (
                                    <Grid alignItems="center" container spacing={2} mt={1} key={field.tkey} style={{ marginTop: '20px' }}>
                                        <Grid item xs={12} md={12}>
                                            <FormControl fullWidth>
                                                <FormLabel id="_animal_label" className='label1'>{field.question}<sup>*</sup></FormLabel>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={12} key={field.tkey}>
                                            <FormControl fullWidth component="fieldset" style={{ border: '1px solid lightgray', marginTop: '-8px', borderRadius: '5px' }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={moment().format('YYYY-MM-DD')}>
                                                    <DatePicker
                                                        fullWidth
                                                        disableFuture={field.future}
                                                        disablePast={field.past}
                                                        localeText={{ toolbarTitle: field.question }}
                                                        value={formValues[field.tkey] || ''}
                                                        onChange={(e) => handleInputChange2(e, field.tkey)}
                                                        slotProps={{
                                                            fieldSeparator: { children: 'to' },
                                                            textField: {
                                                                error: false,
                                                                variant: 'outlined',
                                                                required: field.is_required,
                                                                helperText: `${field.helperText}`
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )

                            default:
                                return null;
                        }
                    })}

                    <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
                        <Typography variant='h6'>Information about a client’s kin</Typography>
                        <Typography variant='caption' style={{ marginTop: '6px' }}>At least one client's kin is mandatory. This information will be used for the well-being of customers</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {renderNextOfKinForm()}
                        <Button variant="outlined" startIcon={<AddIcon />} color='primary' style={{ marginTop: '10px', display: 'none' }} onClick={handleAddNextOfKin}>Add Next of Kin</Button>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {loading === false && <Button variant="contained" fullWidth color='primary' onClick={handleSubmit} style={{ marginTop: '25px', color: 'white' }}
                            disabled={
                                (Object.keys(formValues).length >= data.length && Object.values(nextOfKin[0]).some(value => value === "") === false) ? false : true
                            }>Submit </Button>}

                        {loading === true && <LoadingButton fullWidth size='large' loading variant="outlined" style={{ marginTop: '25px' }}>
                            Submit
                        </LoadingButton>}
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
};

export default CDS;
