import React, { useState } from "react";
import Loader from "components/Loader/Loader";

const LazyImage = ({ src, alt }) => {
    const [loading, setLoading] = useState(true)

    const handleLoad = () => {
        setTimeout(() => {
            setLoading(false)
        }, 200)

    }

    return (
        <>
            {loading && <Loader />}
            <img src={src} alt={alt} onLoad={handleLoad} height={`100%`} width={`100%`}
                style={{
                    visibility: loading ? 'hidden' : 'visible',
                    border: '1px dashed #68BD46'
                }} />
        </>
    )
}

export default LazyImage