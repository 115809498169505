import axios from 'axios';
import { decryptResponse, encryptPayload } from 'helpers/cryptoUtils';

const token = localStorage.getItem('token')

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000, //set this to 15 seconds if not response then it will be a timeout
    headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    },
});
axiosInstance.interceptors.request.use(async config => {
    config.headers['x-sa-version'] = process.env.REACT_APP_VERSION
    if (config.headers['Content-Type'] === 'application/json' && config.data) {
        let encryptedRequest = encryptPayload(config.data);
        config.data = { data: encryptedRequest };
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// decrypt the response
axiosInstance.interceptors.response.use(async (response) => {
    const decryptedData = decryptResponse(response.data.response);
    response.data = decryptedData;
    return response;
}, async error => {
    if (error.code === 'ERR_NETWORK') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 503,
                    status_message: 'No internet connection.Please check your internet connection and try again.',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else if (error.code === 'ECONNREFUSED') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 503,
                    status_message: 'Sorry, we\'re currently experiencing technical difficulties.Please try again later or contact customer support for assistance.',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else if (error.code === 'ECONNABORTED') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 503,
                    status_message: 'The connection to the server was aborted. Please check your internet connection and try again.',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else if (error.code === 'ENOTFOUND') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 503,
                    status_message: 'The requested server could not be found. Please check the URL and try again.',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else if (error.code === 'ETIMEDOUT') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 504,
                    status_message: 'The server took too long to respond. Please check your internet connection and try again.',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else if (error.code === 'EHOSTUNREACH') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 504,
                    status_message: 'Sorry, we\'re unable to reach the server at the moment.Please check your internet connection and try again later.',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else {
        let errorEncrypted = decryptResponse(error.response.data.response);
        return Promise.reject(errorEncrypted);
    }
});

export default axiosInstance