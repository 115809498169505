import { makeStyles } from '@material-ui/core/styles';
import BG_TOP from 'assets/images/bg_top.png'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto'
    },
    pagePaddingTop: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(1),
        },
    },
    mainTitle: {
        lineHeight: '24px',
        fontSize: '17px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        color: 'white'
    },
    shape: {
        backgroundImage: `url(${BG_TOP})`,
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundSize: 'cover',
        height: '10%',
        backgroundPosition: 'center',
        borderBottom: 'none',
        display: 'flex',
        alignContent: 'center'
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '17px',
        fontWeight: 600,
        lineHeight: '24px',
        marginLeft: '8px'
    },
    dateLabel: {
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '20px',
        fontFamily: 'Poppins',
        marginTop: '8px',
        marginLeft: '8px'
    },
    taskTitle: {
        fontWeight: 600,
        marginLeft: '10px',
        fontSize: '22px',
        fontFamily: 'Poppins'
    },
    editIcon: {
        padding: '0px 0px',
        textAlign: 'center',
        margin: '1px 1px',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    sectionNoPadding: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(3),
        },
        backgroundColor: '#F6F6F6'
    },
    sectionNoPaddingTop: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    gridLead: {
        borderRadius: '16px',
        height: '140px',
        backgroundColor: '#F3F2F1',
        padding: '12px 15px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1px 10px 1px 1px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    details: {
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 10px',
        margin: '6px 0px',
        backgroundColor: '#FFF',
        height: '86px'
    },
    tasks: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 12px',
        backgroundColor: '#FFF'
    },
    tasks1: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 12px',
        border: '1px dashed lightgray'
    },
    notasks: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 12px',
        border: '1px dashed red'
    },
    backArrow: {
        padding: '0px 0px',
        margin: '1px 1px',
        lineHeight: '24px',
        fontSize: '17px',
        '&:hover': {
            cursor: 'pointer'
        },
    },

}));

export default useStyles