import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import { Chip, Grid, Typography, Divider } from '@material-ui/core';
import useStyles from './styles'
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from 'services/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { dashboardData } from 'helpers/options/placeholders';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useBottomNav } from 'context/DataContext';
import LinearProgress from '@mui/material/LinearProgress';
import Loader from 'components/Loader/Loader';
import Alert from '@mui/material/Alert';


const Dashboard = () => {
    const [data, setData] = useState([])
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState(null)
    const open = Boolean(anchorEl);
    const [reload, setReload] = useState(false)
    const [response, setResponse] = useState({ status: '', msg: '' })
    const { setBottomNav } = useBottomNav();

    useEffect(() => {
        const isLogin = localStorage.getItem('isLogin')
        const leadId = localStorage.getItem('relatedAgentC')
        const token = localStorage.getItem('token')
        setName(localStorage.getItem('name'))
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        if (isLogin === null || leadId === null || token === null) {
            localStorage.clear()
            navigate('/')
        } else {
            fetchDataboard(leadId)
            const primaryRole = localStorage.getItem('primaryRole') === null || localStorage.getItem('primaryRole') === "undefined" ? 'Agent' : localStorage.getItem('primaryRole')
            const sliceDashboardDetails = primaryRole === 'Agent' ? dashboardData.slice(1) : primaryRole === "STL" ? dashboardData.map(item => ({ ...item, childen: item.childen.filter(child => child.title !== 'Total STL') })) : dashboardData
            setData(sliceDashboardDetails)
        }
    }, [reload])

    const handleClick = (event) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    };
    const handleLogout = () => {
        if (window.confirm('Are you sure you want to logout?')) {
            localStorage.clear()
            navigate('/login')
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchDataboard = async (leadId) => {
        try {
            setLoading(true)
            const primaryRole = localStorage.getItem('primaryRole') === null || localStorage.getItem('primaryRole') === "undefined" ? 'Agent' : localStorage.getItem('primaryRole')
            const response = await axiosInstance.get(`dashboard?leadId=${leadId}&&primaryRole=${primaryRole}`);
            if (response.status === 200) {
                let localData = response.data.body
                setData(localData)
            } else {
                setResponse({ msg: response.data.headers.status_message, status: 'error' })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setResponse({ msg: error.headers.status_message, status: "error" })
            if (error.headers.status_code === 401) {
                localStorage.clear()
                setReload(true)
            }
        }
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.shape}>
                    <CssBaseline />
                    {loading && <Loader />}
                    <AppBar position="fixed" className={classes.appBar}>
                        {loading && <LinearProgress color="secondary" style={{ height: '3px', width: '100%', position: 'relative', }} />}
                        <Toolbar style={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={0}>
                                <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <Typography variant='h6'>Hello {name !== null ? name.split(/\s+/)[0] : 'Agent'}!</Typography>
                                </Grid>
                                <Grid item xs={6} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                    <Tooltip title="Logout">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 1 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}>
                                            <Avatar color="primary" sx={{ width: 40, height: 40 }}><AccountCircleIcon color='white' style={{ width: 45, height: 45 }} /></Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={11} md={11}>
                                    <Typography variant='body2' style={{ margin: '12px 0px' }}>Your Primary role: {localStorage.getItem('primaryRole') === null || localStorage.getItem('primaryRole') === "undefined" ? 'Agent' : localStorage.getItem('primaryRole')}!</Typography>
                                </Grid>
                                <Grid item xs={1} md={1}>
                                    <Typography variant='body2' style={{ margin: '12px 1px', fontSize: '10px', fontWeight: 'bold', textAlign: 'end' }}>v{process.env.REACT_APP_VERSION}</Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Toolbar id="back-to-top-anchor" />




                    <Box component="main" sx={{ p: 3, mb: 3, mt: 2, position: 'relative', }}>
                        {response.status && <Grid spacing={0} container>
                            <Grid item xs={12} md={12}>
                                <Alert severity={response.status} style={{ marginBottom: '3px', marginTop: '10px', fontSize: '12px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{response.msg}</Alert>
                            </Grid>
                        </Grid>}
                        {data.map((d, index) => <Grid key={index}
                            container
                            justifyContent="space-between"
                            spacing={2}
                            direction='row'>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: `${d.bgcolor}`, padding: '15px', borderRadius: '12px', color: 'white', marginTop: '20px' }}>
                                <Typography variant='h6'>{d.title}</Typography>
                                <Typography variant='h6'>{loading === false && d.count} {loading === true && <CircularProgress color="primary" size={20} thickness={2} />}</Typography>
                            </Grid>
                            {d.childen.map((e, indexY) =>
                                <Grid
                                    key={indexY}
                                    item
                                    xs={12} md={12} style={{ marginTop: '4px' }} onClick={() => {
                                        if (e.clickable === true) {
                                            let extractTitle = e.title === 'CDS 1 data collection' ? 'CDS1 Date' : 'KYC Date'
                                            setBottomNav({ activeTab: 1, status: extractTitle })
                                        }
                                    }}>
                                    <Grid container spacing={2} className={e.clickable === true ? classes.mainBar : classes.mainBar1}>
                                        <Grid
                                            item
                                            xs={8} md={8}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '12px', }}>
                                            <Typography noWrap variant='body1' style={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '17px' }}>{e.title}</Typography>
                                            {e.clickable === true && <ArrowForwardIcon style={{ marginLeft: '10px', fontSize: '17px' }} />}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            md={4}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '12px' }}>
                                            <Typography variant='body2' style={{ fontWeight: 600, fontSize: '17px' }}>{loading === false && e.count}{loading === true && <CircularProgress color="primary" size={20} thickness={2} />}</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '5px' }}
                                            md={12}>
                                            {e.labels.map((f, key) => <Chip key={key} label={f.name} style={{
                                                backgroundColor: `${f.color}`, color: 'white',
                                                borderRadius: '8px', padding: '2px, 6px, 2px, 6px', marginRight: '10px'
                                            }} />)}

                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Divider style={{ marginTop: '12px' }} />
                                    </Grid>
                                </Grid>

                            )}
                        </Grid>
                        )}

                        <Typography style={{ marginTop: '25px', fontWeight: 600 }}>Do More</Typography>
                        <Divider style={{ marginTop: '6px' }} />
                        <Grid
                            container
                            spacing={1}
                            justifyContent='betweeen'
                            style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                            <Grid
                                item
                                xs={6}
                                md={6}
                                onClick={() => window.location.href = `https://m.learn.ink/sunculture/learning?customId=${localStorage.employeeIdNumber}`}
                                style={{ color: 'white', cursor: 'pointer', }}>
                                <div style={{ backgroundColor: `green`, borderRadius: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '15px', }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant='body1'>Improve your productivity today</Typography>
                                        <Typography variant='caption'>Click to explore our trainings</Typography>
                                    </div>
                                    <OpenInNewIcon style={{ fontSize: '25px' }} />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                md={6}
                                onClick={() => navigate('/flyers')}
                                style={{ color: 'white', cursor: 'pointer' }}>
                                <div style={{ backgroundColor: `#07B282`, borderRadius: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '15px', }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant='body1'>Get to know about our products</Typography>
                                        <Typography variant='caption'>Click to explore our product list</Typography>
                                    </div>
                                    <ArrowForwardIcon style={{ fontSize: '25px' }} />
                                </div>
                            </Grid>
                        </Grid>


                    </Box>
                </div>
            </div>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <MenuItem onClick={handleLogout} style={{ color: 'red' }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment >
    );
};

export default Dashboard;
