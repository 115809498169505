import React, { useEffect, useState } from 'react'
import worker from './location.worker'
import WebWorker from './WebWorker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from 'services/axiosInstance';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const LocationCollector = () => {
    const [open, setOpen] = useState(false)

    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    }

    function success(pos) {
        var crd = pos.coords
        localStorage.setItem('lat', crd.latitude)
        localStorage.setItem('lng', crd.longitude)
        apiHandler(crd)
    }

    const apiHandler = async (crd) => {
        try {
            let relatedAgentC = localStorage.getItem('relatedAgentC') === null ? 'Anonymous' : localStorage.getItem('relatedAgentC')
            const formData = {
                agentReference: relatedAgentC, leadReference: 'NULL', lng: crd.longitude.toString(), lat: crd.latitude.toString(),
                logType: 'periodic_logging'
            }
            const response = await axiosInstance.post('logging', formData);
            if (response.status !== 200) {
                console.error(`We are unable to complete this request at this time try again later`)
            }
        } catch (error) {
            console.error('Error performing logging:', error);
        }
    }

    function errors(err) {
        console.warn(`ERROR: (${err.code}): ${err.message}`)
    }

    const fetchLatLngBackground = async () => {
        await axios.request({ method: 'get', url: 'https://ipapi.co/json/' }).then(async response => {
            let responseData = await response.data
            localStorage.setItem('lat', responseData.latitude)
            localStorage.setItem('lng', responseData.longitude)
        }).catch((_error) => {
            localStorage.setItem('lat', "0.0")
            localStorage.setItem('lng', "0.0")
        });
    }

    const processRequest = async (event) => {
        if (event.data === 'location.logging') {
            navigator.geolocation.getCurrentPosition(success, errors, options)
        } else if (event.data === 'location.request') {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                localStorage.setItem('lat', latitude)
                localStorage.setItem('lng', longitude)
            }, (error) => {
                console.error("Error get user location: ", error);
            });
        }
    }

    useEffect(() => {
        fetchLatLngBackground()
        const webWorker = new WebWorker(worker)
        webWorker.postMessage('start')
        webWorker.postMessage('gps_status')

        webWorker.addEventListener('message', async event => {
            if (navigator.geolocation) {
                if (navigator.permissions && navigator.permissions.query) {
                    navigator.permissions.query({ name: 'geolocation' }).then(async result => {
                        if (result.state === 'granted') {
                            setOpen(false)
                            processRequest(event)
                        } else if (result.state === 'prompt') {
                            setOpen(false)
                            processRequest(event)
                        } else if (result.state === 'denied') {
                            if (event.data === 'location.logging' || event.data === 'location.request') {
                                setOpen(true)
                            }
                        }
                    }).catch(_error => {
                        fetchLatLngBackground()
                    })
                } else {
                    fetchLatLngBackground()
                }
            } else {
                fetchLatLngBackground()
            }
        });

        return () => {
            webWorker.terminate()
        }

    }, []);

    return (

        <Dialog open={open}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightgray', marginBottom: '8px' }}>
                <span style={{ fontSize: '17px', fontWeight: 600, color: 'red' }}>Important message</span>
                <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ fontSize: '14px' }}>
                    Your app <b>location is disabled</b>, For better experience enabled it
                    <ul style={{ fontSize: '14px' }}>
                        <li> Open Browser Settings from browser of your choice</li>
                        <li> Access Privacy/security settings </li>
                        <li> Navigate to Location permissions</li>
                        <li> Enable the Location Permissions and save changes</li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>

    );
}

export default LocationCollector
