import React from 'react';
import './Spinner.css';

const Spinner = () => (
    <div className='d-flex pos-center justify-content-center'>
        <div className='lds-roller'>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Spinner;