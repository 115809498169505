import React, { useEffect, useState } from 'react';
import { Chip, Grid, TextField, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { placeholders } from 'helpers/options/placeholders';
import Skeleton from '@mui/material/Skeleton';
import EmptyState from 'views/EmptyState';
import axiosInstance from 'services/axiosInstance';
import { cdsStatusList } from 'helpers/options/placeholders';
import Pagination from '@mui/material/Pagination';
import { useBottomNav } from 'context/DataContext';
import { base54Encode } from 'helpers/base54Utils';
import Loader from 'components/Loader/Loader';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const ITEMS_PER_PAGE = 8

const Sales = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [leads, setLeads] = useState([])
    const [agents, setAgents] = useState([])
    const [loading, setLoading] = useState(true)
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [displayedItems, setDisplayedItems] = useState([]);
    const { bottomNav, setBottomNav } = useBottomNav({ activeTab: 1, status: null })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [_primaryRole, setPrimaryRole] = useState("")
    const [filterType, setFilterType] = useState("null")
    const [selectedAgent, setSelectedAgent] = useState({ name: localStorage.getItem('name'), agentId: localStorage.getItem('relatedAgentC'), status: true })

    const handleChange = (event, newValue) => {
        setPage(1)
        setFilterType(newValue);
    };

    const handlePageChange = (_event, value) => {
        setPage(value);
        updateDisplayedItems(value, leads);
    };

    useEffect(() => {
        setSelectedAgent({ name: localStorage.getItem('name'), agentId: localStorage.getItem('relatedAgentC'), status: true })
        const PR = localStorage.getItem('primaryRole') === null || localStorage.getItem('primaryRole') === "undefined" ? 'Agent' : localStorage.getItem('primaryRole')
        fetchAgents()
        setPrimaryRole(PR)
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setPage(1)
        fetchLeads()
    }, [bottomNav])

    useEffect(() => {
        setAnchorEl(null)
        setPage(1)
        if (search !== null) {
            fetchLeads()
        }
        if (selectedAgent !== null) {
            fetchLeads()
        }
    }, [search, selectedAgent, filterType])


    const fetchLeads = async () => {
        try {
            setLoading(true)
            const localStatus = bottomNav.status === "" ? null : bottomNav.status
            const response = await axiosInstance.get(`leads?agentId=${selectedAgent.agentId}&status=${localStatus}&filterType=${filterType}&search=${search}&&limit=1000&&offset=0`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                setLeads(records)
                const calculateTotalPages = Math.ceil(records.length / ITEMS_PER_PAGE);
                setTotalPages(calculateTotalPages);
                updateDisplayedItems(page, records);
                setResponse({ status: '', msg: '' })
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
            setTimeout(() => {
                if (error.headers.status_code === 401) {
                    localStorage.clear()
                    navigate("/login")
                }
            }, 1000)
        }
    }

    const fetchAgents = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`agents/supervisor?agentId=${localStorage.getItem('relatedAgentC')}`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                setAgents(records)
                setResponse({ status: '', msg: '' })
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
            setTimeout(() => {
                if (error.headers.status_code === 401) {
                    localStorage.clear()
                    navigate("/login")
                }
            }, 1000)
        }
    }

    const updateDisplayedItems = (currentPage, itemsList) => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        let sliceLeads = itemsList.slice(startIndex, endIndex)
        setDisplayedItems(sliceLeads);
    };

    const goToLeadDetails = (d) => {
        if (d.mobilePhone === "" || d.mobilePhone === null) {
            alert('Lead mobile number cannot be null')
        } else {
            let params = `agentId=${d.agentId}&&leadId=${d.id}&&accountRef=${d.idNumber}&&mobilePhone=${d.mobilePhone.replace(/\s/g, '')}`
            const encodedText = base54Encode(params)
            navigate(`/view/lead-detail?params=${encodedText}`)
        }
    }

    return (
        <>
            <div className={classes.root}>
                {loading && <Loader />}
                <div className={classes.shape}>
                    <Box component="main" sx={{ p: 3, mb: 0, mt: 0, width: '100%' }}>
                        <Grid
                            container
                            justifyContent="center"
                            spacing={2}>
                            <Grid item xs={12}
                                md={12}
                                data-aos={'fade-up'}>
                                <TextField
                                    placeholder="Search by Name, ID or Phone"
                                    variant="outlined"
                                    size="medium"
                                    fullWidth
                                    value={search === null ? '' : search}
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setSearch(e.target.value)
                                        } else {
                                            setSearch(null)
                                        }
                                    }}
                                    name="searchText"
                                    type="text"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ marginRight: '8px', color: '#A1A1A1' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{
                                        fontFamily: 'Poppins',
                                        backgroundColor: 'white',
                                        '& .MuiOutlinedInputNotchedOutline': {
                                            borderColor: 'white',
                                        },
                                        borderRadius: '12px',
                                        fontSize: '15px'
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                data-aos={'fade-up'}>
                                <Autocomplete
                                    fullWidth
                                    options={cdsStatusList}
                                    value={bottomNav.status}
                                    onChange={(_event, newValue) => {
                                        if (newValue !== null) {
                                            setBottomNav({ activeTab: 1, status: newValue })

                                        } else {
                                            setBottomNav({ activeTab: 1, status: null })
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ backgroundColor: 'white', fontSize: '15px', fontFamily: 'Poppins' }}>{children}</Paper>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            variant="outlined"
                                            placeholder="Status"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <FilterAltIcon style={{ marginRight: '8px', color: '#A1A1A1' }} />
                                                ),
                                            }}
                                            style={{
                                                fontFamily: 'Poppins',
                                                backgroundColor: 'white',
                                                '& .MuiOutlinedInputNotchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                borderRadius: '12px',
                                                fontSize: '15px'
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            {filterType === "null" && <Grid
                                item
                                xs={12}
                                md={12}
                                data-aos={'fade-up'}>
                                <Autocomplete
                                    fullWidth
                                    options={agents}
                                    value={selectedAgent}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_event, newValue) => {
                                        if (newValue !== null) {
                                            setSelectedAgent(newValue)
                                        } else {
                                            setSelectedAgent({ name: localStorage.getItem('name'), agentId: localStorage.getItem('relatedAgentC'), status: true })
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ backgroundColor: 'white', fontSize: '15px', fontFamily: 'Poppins' }}>{children}</Paper>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Agent"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <FilterAltIcon style={{ marginRight: '8px', color: '#A1A1A1' }} />
                                                ),
                                            }}
                                            style={{
                                                fontFamily: 'Poppins',
                                                backgroundColor: 'white',
                                                '& .MuiOutlinedInputNotchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                borderRadius: '12px',
                                                fontSize: '15px'
                                            }}
                                        />
                                    )}
                                />
                            </Grid>}
                        </Grid>
                    </Box>
                </div>

                <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>
                    <Grid container spacing={0} style={{ marginTop: 0 }}>
                        <Box sx={{ width: '100%', borderBottom: '1px solid lightgray' }}>
                            <Tabs
                                value={filterType}
                                onChange={handleChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="secondary tabs example">
                                <Tab value="null" label="My Leads" />
                                <Tab value="ALL" label="All Leads" />
                            </Tabs>
                        </Box>



                        {loading === false && displayedItems.map((d, index) => <>
                            <Grid id={index} container key={index} spacing={0} onClick={() => {
                                goToLeadDetails(d)
                            }} className={classes.gridLead} aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}>
                                <Grid item xs={10} md={10}>
                                    <Typography variant="body1" style={{ fontFamily: 'Poppins', fontSize: '17px', fontWeight: 600, textTransform: 'capitalize' }} gutterBottom>{d.name}</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Account ref: <b>{d.idNumber}</b>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        P/N: <b>{d.mobilePhone}</b>
                                    </Typography>
                                    <div style={{ display: 'flex' }}><Chip size='small' label={d.leadStatus} /> <Chip size='small' label={d.leadCategory} style={{ marginLeft: '5px', backgroundColor: d.leadCategory === 'Hot' ? '#1BAEAB' : d.leadCategory === 'Warm' ? '#E6D403' : '#FF3900', color: 'white' }} /></div>
                                </Grid>
                            </Grid>


                        </>
                        )}

                        {loading === false && leads.length === 0 && <EmptyState title={'No Results found'} description={response.msg === "" ? `We could not find any leads matching your records.` : response.msg} buttonText={null} url={null} />}

                        {loading === true && placeholders.map((_d, index) =>
                            <Skeleton key={index} animation="pulse" variant="rectangular" width={'100%'} height={90} style={{ marginTop: '16px', borderRadius: '12px' }} />
                        )}
                        {displayedItems.length > 0 && totalPages > 1 && <Grid className={classes.paginationcontainer} item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center', border: '1px dotted lightgray', borderRadius: '2px', padding: '5px' }}>
                            <Pagination
                                hideNextButton={false}
                                hidePrevButton={false}
                                size='small'
                                shape='circular'
                                defaultPage={1}
                                count={totalPages}
                                siblingCount={2}
                                page={page}
                                color='primary'
                                onChange={handlePageChange}
                                variant='outlined'
                            />
                        </Grid>}

                    </Grid>

                    {loading === false && <div className={classes.fabcontainer}> <Fab className={classes.fab} aria-label="add" onClick={() => navigate('/view/lead-registration')}>
                        <AddIcon />
                    </Fab></div>}
                </Box>
            </div>

        </>

    );
};

export default Sales;
