import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from './styles';
import { useNavigate } from 'react-router-dom';
import Flyer1 from 'assets/images/flyer-1.svg';
import Flyer2 from 'assets/images/flyer-2.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LazyImage from 'components/LazyImage/LazyImage';

const Flyer = () => {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <div className={classes.root}>
            <div className={classes.shape}>
                <Box component="main" sx={{ p: 3, mb: 0, mt: 0, width: '100%' }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems='center'
                        spacing={0}
                        direction='row'>
                        <Grid
                            item
                            xs={4}
                            onClick={() => navigate('/home')}
                            md={6}
                            data-aos={'fade-up'}
                            style={{ display: 'flex', color: 'white', alignItems: 'center', flexDirection: 'row' }} className={classes.backArrow}>
                            <ArrowBackIosIcon /> <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            md={6}
                            data-aos={'fade-up'}>
                            <Typography variant='h6' className={classes.mainTitle}>Flyers</Typography>
                        </Grid>
                    </Grid>
                </Box>

            </div>



            <Grid
                container
                justifyContent="space-between"
                spacing={0}
                style={{ margin: '5px 0px', }}
                direction='row'>
                <Grid
                    item
                    xs={12}
                    md={6}>
                    <LazyImage src={Flyer1} alt={'flyer one'} />

                </Grid>
                <Grid item
                    xs={12}
                    md={6}>
                    <LazyImage src={Flyer2} alt={'flyer one'} />
                </Grid>
            </Grid>

        </div>
    );
};

export default Flyer;
