import CryptoJS from 'crypto-js';
const encryptionKey = process.env.REACT_APP_ENCRYPT_KEY;



export const encryptPayload = (payload) => {
    const jsonString = JSON.stringify(payload);
    const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();

    return encrypted;
};


export const decryptResponse = (encryptedResponse) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedResponse, encryptionKey).toString(CryptoJS.enc.Utf8);
    const decryptedJson = JSON.parse(decrypted);

    return decryptedJson;
};