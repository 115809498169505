import React, { useEffect } from 'react';
import ReactDOM from "react-dom/client";
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme';
import reportWebVitals from './pwa/reportWebVitals'
import * as serviceWorkerRegistration from './pwa/serviceWorkerRegistration';
import App from './App';
import "./index.css"
import { captureConsoleIntegration, reportingObserverIntegration, debugIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";

// {
// 	Sentry.init({
// 		dsn: process.env.REACT_APP_SENTRY_DNS,
// 		maxBreadcrumbs: 50,
// 		debug: (process.env.REACT_APP_ENV === 'production') ? false : true,
// 		environment: process.env.REACT_APP_ENV,
// 		release: `sales-app:${process.env.REACT_APP_VERSION}`,
// 		integrations: [
// 			Sentry.browserTracingIntegration(),
// 			Sentry.browserProfilingIntegration(),
// 			Sentry.replayIntegration(),
// 			captureConsoleIntegration(),
// 			reportingObserverIntegration(),
// 			debugIntegration()
// 		],
// 		tracesSampleRate: 1.0,
// 		tracePropagationTargets: ["localhost", /^https:\/\/sales.sunculture\.io\/api/],
// 		replaysSessionSampleRate: 0.1,
// 		replaysOnErrorSampleRate: 1.0,
// 		profilesSampleRate: 1.0,
// 	});
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ThemeProvider theme={theme}>
		<App />
	</ThemeProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();
