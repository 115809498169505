import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { NavBottom } from 'layouts';
import axios from 'axios';
import {
	Signin as SigninView,
	Otp as OtpView,
	NotFound as NotFoundView,
	LeadDetail as LeadDetailView,
	Dashboard as DashboardView,
	LeadReg as LeadRegView,
	NewTask as NewTaskView,
	CDS as CDSView,
	KYC as KYCView,
	Flyer as FlyerView,
	TagRequest as TagRequestView
} from './views';
import { Grid } from '@mui/material';
import axiosInstance from 'services/axiosInstance';
import Spinner from 'components/Spinner/Spinner';
import useStyles from './styles'


const AppRoutes = () => {
	const classes = useStyles();
	const [flag, setFlag] = useState(false);
	const [loading, setLoading] = useState(true)
	const location = useLocation();

	const fetchLatLngBackground = async () => {
		await axios.request({ method: 'get', url: 'https://ipapi.co/json/' }).then(async (response) => {
			let responseData = await response.data
			localStorage.setItem('lat', responseData.latitude)
			localStorage.setItem('lng', responseData.longitude)
		}).catch((error) => {
			console.log(error);
			localStorage.setItem('lat', "0.0")
			localStorage.setItem('lng', "0.0")
		});
	}

	useEffect(() => {
		fetchLatLngBackground()
	}, [location]);

	useEffect(() => {
		setLoading(true);
		const token = localStorage.getItem('token');
		fetchLatLngBackground()
		if (token) {
			try {
				axiosInstance.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
				axiosInstance.get(`jwttoken/validation`).then((res) => {
					setFlag(res.data.headers.status);
				}).catch(() => {
					setFlag(false);
				}).finally(() => setLoading(false));
			} catch (error) {
				setFlag(false);
				setLoading(false);
			}
		} else {
			setLoading(false)
		}
	}, [])
	return (
		<>
			{loading ? (
				<Grid container className={classes.root} justify="center" alignItems="center">
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
						<Spinner />
					</Grid>
				</Grid>
			) : (
				<Routes>

					<Route
						path="/"
						element={
							flag ? (
								<Navigate to="/home" />
							) : (
								<Navigate to="/login" />
							)
						}
					/>

					<Route path="/login" element={<SigninView />} />

					<Route path="/view/otp" element={<OtpView />} />

					<Route path="/view/lead-detail" element={<LeadDetailView />} />

					<Route path="/view/lead" element={<DashboardView />} />

					<Route path="/view/lead-registration" element={<LeadRegView />} />

					<Route path="/view/new-task" element={<NewTaskView />} />

					<Route path="/view/cds" element={<CDSView />} />

					<Route path="/view/kyc" element={<KYCView />} />

					<Route path="/home" element={<NavBottom />} />

					<Route path="/flyers" element={<FlyerView />} />

					<Route path="/tag-request" element={<TagRequestView />} />

					<Route path="*" element={<NotFoundView />} />
				</Routes>
			)}
		</>
	);

};

export default AppRoutes;
