const palette = {
	"alternate": {
		"main": "rgb(247, 249, 250)",
		"dark": "#e8eaf6",
	},
	"cardShadow": "rgba(23, 70, 161, .11)",
	"common": {
		"black": "#000",
		"white": "#fff"
	},
	"type": "light",
	"primary": {
		"main": "#07B282",
		"light": "rgb(71, 145, 219)",
		"dark": "rgb(17, 82, 147)",
		"contrastText": "#fff"
	},
	"secondary": {
		"light": "#FF0000",
		"main": "#FF0000",
		"dark": "#FF0000",
		"contrastText": "rgba(0, 0, 0, 0.87)"
	},
	"error": {
		"light": "#ff0000",
		"main": "#ff0000",
		"dark": "#ff0000"
	},
	"warning": {
		"light": "#ffb74d",
		"main": "#ff9800",
		"dark": "#f57c00",
		"contrastText": "rgba(0, 0, 0, 0.87)"
	},
	"info": {
		"light": "#64b5f6",
		"main": "#2196f3",
		"dark": "#1976d2",
		"contrastText": "#fff"
	},
	"success": {
		"light": "#81c784",
		"main": "#4caf50",
		"dark": "#388e3c",
		"contrastText": "rgba(0, 0, 0, 0.87)"
	},
	"grey": {
		"50": "#fafafa",
		"100": "#f5f5f5",
		"200": "#eeeeee",
		"300": "#e0e0e0",
		"400": "#bdbdbd",
		"500": "#9e9e9e",
		"600": "#757575",
		"700": "#616161",
		"800": "#424242",
		"900": "#212121",
		"A100": "#d5d5d5",
		"A200": "#aaaaaa",
		"A400": "#303030",
		"A700": "#616161"
	},
	"contrastThreshold": 3,
	"tonalOffset": 0.2,
	"text": {
		"primary": "rgba(0, 0, 0, 0.87)",
		"secondary": "rgba(0, 0, 0, 0.54)",
		"disabled": "rgba(0, 0, 0, 0.38)",
		"hint": "rgba(0, 0, 0, 0.38)"
	},
	"divider": "rgba(0, 0, 0, 0.12)",
	"background": {
		"paper": "#fff",
		"default": "#fff",
		"level2": "#f5f5f5",
		"level1": "#fff",
		"footer": "#1b1642",
	},
	"action": {
		"active": "rgba(0, 0, 0, 0.54)",
		"hover": "rgba(0, 0, 0, 0.04)",
		"hoverOpacity": 0.04,
		"selected": "rgba(0, 0, 0, 0.08)",
		"selectedOpacity": 0.08,
		"disabled": "rgba(0, 0, 0, 0.26)",
		"disabledBackground": "rgba(0, 0, 0, 0.12)",
		"disabledOpacity": 0.38,
		"focus": "rgba(0, 0, 0, 0.12)",
		"focusOpacity": 0.12,
		"activatedOpacity": 0.12
	}
};

export default palette;