import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },

    label: {
        fontSize: '11px !important'
    },
}))

export default useStyles