import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import LoadingButton from '@mui/lab/LoadingButton';
import { Image } from 'components/atoms';
import Logo from 'assets/images/logo.png'
import { Grid, Button, TextField } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import axiosInstance from 'services/axiosInstance';

const phoneValidator = new RegExp('^(0)([17][0-9]|[1][0-1]){1}[0-9]{1}[0-9]{6}$')

const Signin = () => {
    const [query, setQuery] = useState("")
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({ status: "", msg: "" })
    const navigate = useNavigate()

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });
    const classes = useStyles();

    const handleSubmit = event => {
        event.preventDefault();

        if (formState.isValid) {
            window.location.replace('/');
        }

        setFormState(formState => ({ ...formState, touched: { ...formState.touched, ...formState.errors } }));
    };

    const hasError = field => formState.touched[field] && formState.errors[field] ? true : false;


    const loginRequest = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await axiosInstance.post(`check-agent`, { phoneNumber: query, lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') });
            setLoading(false)
            if (response.status === 200) {
                let data = response.data.body
                let headers = response.data.headers
                localStorage.setItem('query', data.phoneNumber)
                localStorage.setItem("relatedAgentC", data.agentId)
                localStorage.setItem("phoneNumber", data.phoneNumber)
                setResponse({ status: "success", msg: headers.status_message })
                navigate("/view/otp")
            } else {
                setResponse({ status: "error", msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            if (error.code === 'ERR_NETWORK') {
                setResponse({ status: 'error', msg: `${error.message} - Please check it and try again` })
            } else if (error.code === 'ERR_BAD_REQUEST') {
                setResponse({ status: 'error', msg: `${error.message} - Please check it and try again` })
            } else {
                setResponse({ status: "error", msg: error.headers.status_message })
            }
        }
    }

    return (
        <div className={classes.root}>
            <Section className={classes.section}>
                <div className={classes.formContainer}>
                    <Image
                        className={classes.logoImage}
                        src={Logo}
                        alt="Sales app logo"
                        lazy={true} />
                    <SectionHeader
                        title="Welcome to Sales Portal ✋🏾"
                        subtitle="Login into your account."
                        titleProps={{
                            variant: 'h5',
                        }}
                        subtitleProps={{
                            variant: 'body1'
                        }}
                        border={{
                            variant: '1px solid red'
                        }}
                        style={{
                            marginTop: '15px'
                        }}
                    />

                    <div className={classes.root}>
                        {response.status !== "" && <Alert severity={'error'} style={{ marginBottom: '14px', fontSize: '12px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>{response.msg}</Alert>}
                        <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        placeholder="Phone Number"
                                        variant="outlined"
                                        size="medium"
                                        name="phoneNumber"
                                        fullWidth
                                        helperText={hasError('query') ? formState.errors.query[0] : null}
                                        error={hasError('query')}
                                        onChange={(e) => setQuery(e.target.value)}
                                        type="number"
                                        value={query}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    {loading === false && <Button
                                        disableElevation
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        className={classes.submitButton}
                                        disabled={phoneValidator.test(query) ? false : true}
                                        onClick={(e) => loginRequest(e)}
                                        fullWidth>
                                        Continue
                                    </Button>
                                    }
                                    {loading && <LoadingButton fullWidth size='large' loading variant="outlined">
                                        Submit
                                    </LoadingButton>}
                                </Grid>

                            </Grid>
                        </form>
                    </div>
                </div>
            </Section>
        </div>
    );
};

export default Signin;
