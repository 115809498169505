export const placeholders = [1, 2, 3, 4, 5, 6, 7, 8]

export const howLongHaveYouHadSamePhoneNoC = ['Less than a year', '1 year', '2 years', '3 years', '4 years', '5 years', '6 years']

export const cdsStatusList = ['Monthly Installment', 'Installation', 'Deposit Payment', 'Lead Converted', 'CDS2 Date', 'CDS1 Date', 'KYC Date', 'No Status', 'Refunded'];

export const dashboardData = [{
    title: 'Total Agents',
    count: '0',
    bgcolor: '#56566E',
    label: '',
    childen: [{
        title: 'Total Agents',
        count: '0',
        clickable: false,
        labels: []
    }, {
        title: 'Total STL',
        count: '0',
        clickable: false,
        labels: []
    }]
}, {
    title: 'Total leads',
    count: '0',
    bgcolor: '#56566E',
    label: 'Customer deposit',
    childen: [{
        title: 'CDS 1 data collection',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }, {
            name: 'CASH',
            color: '#252543'
        }]
    }, {
        title: 'KYC done',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }, {
            name: 'CASH',
            color: '#252543'
        }]
    }]
}, {
    title: 'Total accounts',
    count: '0',
    bgcolor: '#56566E',
    label: 'Customer deposit',
    childen: [{
        title: 'Customer deposit',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }]
    }, {
        title: 'Partial deposit',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }]
    }, {
        title: 'Credit check (CDS2)',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }]
    }, {
        title: 'Payment',
        count: '0',
        labels: [{
            name: 'CASH',
            color: '#252543'
        }]
    }, {
        title: 'Partial Payment',
        count: '0',
        labels: [{
            name: 'CASH',
            color: '#252543'
        }]
    }, {
        title: 'JSF Installation',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }, {
            name: 'CASH',
            color: '#252543'
        }]
    }, {
        title: 'First Month of Payment',
        count: '0',
        labels: [{
            name: 'CREDIT',
            color: '#3897E0'
        }]
    }]
}, {
    title: 'Total sales',
    count: '0',
    bgcolor: 'green',
    label: 'Customer deposit',
    childen: []
}]