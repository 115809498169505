import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import ICON404 from '../../assets/images/404.svg'

const useStyles = makeStyles(theme => ({
	root: {},
	formContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
		maxWidth: 500,
		margin: `0 auto`,
	},
	section: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	label: {
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
}));

const NotFound = () => {
	const classes = useStyles();

	const handleClick = () => {
		window.history.back();
	};

	return (
		<div className={classes.root}>
			<Section className={classes.section}>
				<div className={classes.formContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<img src={ICON404} height={300} width={300} />
						</Grid>
					</Grid>
					<SectionHeader
						label="Not found"
						subtitle={
							<span>
								The page you are looking for is not found. Please retry again.
							</span>
						}
						titleProps={{
							variant: 'h3',
						}}
						labelProps={{
							color: 'secondary',
							className: classes.label,
							variant: 'h5',
						}}
						ctaGroup={[
							<Button
								size="large"
								variant="contained"
								color="primary"
								onClick={handleClick}>
								Go Back
							</Button>,
						]}
						disableGutter
					/>
				</div>
			</Section>
		</div>
	);
};

export default NotFound;
