import { makeStyles } from '@material-ui/core/styles';
import BG_TOP from 'assets/images/bg_top.png'
const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto',
        height: '100vh'
    },
    pagePaddingTop: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(3),
        },
    },
    tabs: {
        borderBottom: 'none',
        width: '100%',
        borderRadius: '8px',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center'
    },
    sectionNoPadding: {
        paddingTop: 0,
        backgroundColor: '#F6F6F6'
    },
    shape: {
        backgroundImage: `url(${BG_TOP})`,
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        backgroundSize: 'cover',
        borderBottom: 'none',
        padding: '5%',
        display: 'flex'
    },
}));

export default useStyles