import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { FormControl, Grid, Typography, TextField } from '@material-ui/core';
import useStyles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
import axiosInstance from 'services/axiosInstance';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';

const Comment = ({ taskId, description }) => {
    const [formValues, setFormValues] = useState({});
    const [length, setLength] = useState(0)
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [tasks, setTasks] = useState([])
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [reload, setReload] = useState(false)

    useEffect(() => {
        fetchLeads()
    }, [])

    useEffect(() => {
        fetchLeads()
    }, [reload])

    const fetchLeads = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`comments?taskId=${taskId}`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                setTasks(records)
                setResponse({ status: '', msg: '' })
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
            setTimeout(() => {
                if (error.headers.status_code === 401) {
                    localStorage.clear()
                    navigate("/login")
                }
            }, 1000)
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShow(open)
    };

    const handleInputChange = (event, tkey) => {
        const { value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: value }));
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            Object.assign(formValues, { taskId: taskId, lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') })
            const response = await axiosInstance.post('comments', formValues);
            if (response.status === 200) {
                setReload(!reload)
                setResponse({ status: 'success', msg: 'New Comment has been added' })
                setTimeout(() => {
                    toggleDrawer(false)
                }, 2000)
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setResponse({ status: 'error', msg: 'Request failed. Please try again' })

        }
        setFormValues({})
    }


    return (
        <div>

            <Badge badgeContent={tasks.length.toString()} color="primary" size="small">
                <Button variant='outlined' style={{ marginLeft: '5px' }} onClick={toggleDrawer(true)} size='small'>{'Comment'}</Button>
            </Badge>

            <Drawer
                anchor={'bottom'}
                open={show}
                onClose={toggleDrawer(false)}>
                <Box
                    component={'main'}
                    sx={{ width: 'auto', p: 3, mb: 2 }}
                    role="presentation">
                    <Grid container spacing={0}>

                        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <Typography variant='h6'>Comments</Typography>
                            <CloseIcon color='secondary' size="large" onClick={toggleDrawer(false)} style={{ cursor: 'pointer' }} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Divider fullWidth style={{ marginTop: '5px' }} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {response.status !== "" && <Alert severity={response.status} style={{ marginBottom: '4px' }}>{response.msg}!</Alert>}
                        </Grid>
                        {tasks.length !== 0 && <Grid item xs={12} md={12} key={`description`} style={{ marginTop: '4px' }}>
                            {tasks.map((d, i) => (
                                <Grid key={i} container spacing={0} className={classes.tasks1}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant='body2' style={{ fontSize: '15px' }}>{d.comment}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6} style={{ display: 'flex', alignContent: 'start', marginTop: '8px' }}>
                                        <Typography variant='caption'>{moment(d.createdAt).format('YYYY-MM-DD')}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>}

                        <Grid item xs={12} md={12} key={`description`} style={{ marginTop: '0px' }}>
                            <FormControl fullWidth component="fieldset">
                                <TextField
                                    margin="normal"
                                    required
                                    size="small"
                                    fullWidth
                                    error={length > 200 ? true : false}
                                    multiline
                                    minRows={4}
                                    placeholder='Type your comment here..'
                                    maxRows={7}
                                    variant='outlined'
                                    helperText={`${length}/200`}
                                    value={formValues['comment'] || ''}
                                    onChange={e => {
                                        setLength(e.target.value.length)
                                        handleInputChange(e, 'comment')
                                    }}
                                    name={`comment`}
                                    type="text"
                                    id={`comment`}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {loading === false && <Button variant="contained" disableElevation fullWidth color='primary' onClick={handleSubmit} style={{ color: 'white' }}
                                disabled={
                                    Object.keys(formValues).length >= 1 && length <= 200 ? false : true
                                }>Submit</Button>
                            }

                            {loading && <LoadingButton fullWidth size='large' loading variant="outlined">
                                Submit
                            </LoadingButton>}
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </div>
    );
}

export default Comment;