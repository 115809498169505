import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Section } from 'components/organisms';
import useStyles from './styles';
import EmptyState from 'views/EmptyState';
import Skeleton from '@mui/material/Skeleton';

const notices1 = [{
    title: 'Autumn Phillips has been transferred to another agent',
    date: '2023-07-21'
}, {
    title: 'You have been tagged Wade Warren',
    date: '2023-07-21'
}, {
    title: 'You have been tagged Wade Warren',
    date: '2023-07-21'
}, {
    title: 'You have been tagged Wade Warren',
    date: '2023-07-21'
}, {
    title: 'You have been tagged Wade Warren',
    date: '2023-07-21'
}, {
    title: 'You have been tagged Wade Warren',
    date: '2023-07-21'
}]

const Notices = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [notices, setNotices] = useState([])
    const [response, setResponse] = useState({ status: "", msg: "" })

    return (
        <div className={classes.root}>
            <div className={classes.shape}>
                <Section className={classes.pagePaddingTop}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        direction='row'>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            data-aos={'fade-up'}>
                            <Typography variant='h4' style={{ fontWeight: 600, fontSize: '22px' }}>Notices</Typography>
                        </Grid>

                    </Grid>
                </Section>
            </div>

            <Section className={classes.sectionNoPaddingTop}>
                {loading === false && notices.map((d, index) =>
                    <Grid key={index} container spacing={0} style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 10px' }}>
                        <Grid item xs={12} md={12}>
                            <Typography variant='body2' style={{ fontWeight: 400, fontSize: '15px', fontFamily: 'Poppins' }}>{d.title}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ display: 'flex', alignContent: 'start', marginTop: '12px', fontFamily: 'Poppins' }}>
                            <Typography variant='caption'>{d.date}</Typography>
                        </Grid>
                    </Grid>
                )}

                {loading === false && notices.length === 0 && <EmptyState title={'No Notices found'} description={response.msg === "" ? `We could not find any notices matching your records.` : response.msg} buttonText={null} url={null} />}

                {loading === true && placeholders.map((_d, index) =>
                    <Skeleton key={index} animation="wave" variant="rectangular" width={'100%'} height={90} style={{ marginTop: '12px', borderRadius: '8px' }} />
                )}
            </Section>
        </div>
    );
};

export default Notices;
