import { makeStyles } from '@material-ui/core/styles';
import BG_TOP from 'assets/images/bg_top.png'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto'
    },
    pagePaddingTop: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    shape: {
        backgroundImage: `url(${BG_TOP})`,
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundSize: 'cover',
        height: '10%',
        backgroundPosition: 'center',
        borderBottom: 'none',
        display: 'flex',
        alignContent: 'center'
    },
    backArrow: {
        padding: '0px 0px',
        margin: '1px 1px',
        lineHeight: '24px',
        fontSize: '17px',
        '&:hover': {
            cursor: 'pointer'
        },
    },
}))

export default useStyles