import React, { useState, useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Grid, } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from 'services/axiosInstance';
import Alert from '@mui/material/Alert';
import useStyles from './styles';
import moment from 'moment'
import Comment from 'views/Comment/Comment';
import Loader from 'components/Loader/Loader';

const steps = [
    {
        label: 'Overdue Tasks',
        task: [],
    },
    {
        label: 'Today Tasks',
        task: []
    },
    {
        label: 'Upcoming Tasks',
        task: []
    },
];

export default function TaskStepper({ tasks, type }) {
    const [activeStep, setActiveStep] = useState(0);
    const [taskInfo, setTaskInfo] = useState([])
    const [open, setOpen] = useState(false)
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [taskId, setTaskId] = useState("")
    const [loading, setLoading] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        setTaskInfo(tasks)
        const today = moment().startOf('day');
        if (tasks.length > 0) {
            const delayedTasks = tasks.filter(item => {
                const taskDate = moment(item.taskDate);
                return taskDate.isBefore(today);
            });

            steps[0].task = delayedTasks

            const todayTask = tasks.filter(item => {
                const taskDate = moment(item.taskDate);
                return taskDate.isSame(today, 'day');
            });
            steps[1].task = todayTask

            const tasksAfterToday = tasks.filter(item => {
                const taskDate = moment(item.taskDate);
                return taskDate.isAfter(today, 'day');
            });

            steps[2].task = tasksAfterToday

            setTaskInfo(steps)
        }
    }, [tasks])

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        setLoading(true)
        setOpen(false);
        try {
            const response = await axiosInstance.put(`tasks`, {
                "status": "Closed",
                "taskId": taskId,
                lat: localStorage.getItem('lat'),
                lng: localStorage.getItem('lng')
            });
            setLoading(false)
            if (response.status === 200) {
                setResponse({ msg: 'Task has been updated successful.', status: 'success' })
            } else {
                setResponse({ msg: 'Task has not been updated.', status: 'error' })
            }
        } catch (error) {
            setLoading(false)
            setResponse({ msg: 'Task has not been updated.', status: 'error' })
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const truncateLeadName = (leadName) => {
        if (leadName && typeof leadName === 'string') {
            const names = leadName.trim().split(' ');
            if (names.length === 3) {
                return `${names[0]} ${names[1]}`;
            } else {
                return leadName;
            }
        } else {
            return '---';
        }
    }

    return (
        <>
            {loading && <Loader />}
            {response.status && <Grid spacing={3} container style={{ marginBottom: '10px' }}>
                <Grid item xs={12} md={12}>
                    <Alert severity={response.status} style={{ marginBottom: '6px', fontSize: '12px' }}>{response.msg}</Alert>
                </Grid>
            </Grid>}
            <Grid item xs={12} md={12} style={{ backgroundColor: 'white' }}>
                <Stepper orientation="vertical">
                    {taskInfo.map((step, index) => (
                        <Step key={index} expanded={true}>
                            <StepLabel>
                                <Typography variant='body1'>{step.label}</Typography>
                            </StepLabel>

                            <StepContent style={{ marginTop: '10px' }}>
                                {type === 'detail' && step.task.length > 0 && step.task.map((d, i) =>
                                    <Grid key={i} container spacing={0} className={classes.tasks1}>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant='body2' style={{ fontSize: '15px' }}>{d.description}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', alignContent: 'start', marginTop: '8px' }}>
                                            <Typography variant='caption'>{moment(d.taskDate).format('YYYY-MM-DD')}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                                            <Button disableElevation style={{ borderRadius: '4px' }} variant="outlined" color='secondary' size='small' onClick={() => {
                                                setOpen(true)
                                                setTaskId(d.id)
                                            }}>Close</Button>
                                            <Comment taskId={d.id} description={d.description} />
                                        </Grid>
                                    </Grid>
                                )}

                                {step.task.length === 0 && <Grid container className={classes.notasks}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant='caption' style={{ padding: '10px', color: 'red' }}>No {step.label} </Typography>
                                    </Grid>
                                </Grid>}

                                {type === 'main' && step.task.length > 0 && step.task.map((d, index) =>

                                    <Grid key={index} container spacing={0} style={{ marginTop: index === 0 ? '-8px' : '12px', display: 'flex', justifyContent: 'space-between', border: '1px dashed lightgray', alignItems: 'center', padding: '12px 16px' }}>
                                        <Grid item xs={6} md={6}>
                                            <Typography variant='body2'>Client:</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant='body2' style={{ fontWeight: 600 }}>{truncateLeadName(d.leadName)}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
                                            <Typography variant='body2'>{d.description}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', alignContent: 'start', marginTop: '8px' }}>
                                            <Typography variant='caption'>{moment(d.taskDate).format('YYYY-MM-DD')}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                                            <Button disableElevation variant="outlined" color='secondary' size='small' onClick={() => {
                                                setOpen(true)
                                                setTaskId(d.id)
                                            }}>Close</Button>
                                            <Comment taskId={d.id} description={d.description} />
                                        </Grid>
                                    </Grid>
                                )}
                            </StepContent>
                        </Step>
                    ))}


                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Todays Task
                        </Button>
                    </Paper>
                )}
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to perform this action?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} size='small' color="secondary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} size='small' color="primary" variant='contained' autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}