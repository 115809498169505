import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto'
    },
    sectionNoPaddingTop: {
        paddingTop: 0,
    },
    shape: {
        background: '#FFF',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderBottom: 'none'
    },
    pagePaddingTop: {
        position: 'relative',
        paddingTop: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(5),
        },
    },
    appBar: {
        position: 'fixed',
        backgroundColor: '#07B282 !important',
        color: 'white !important'
    },
    mainBar: {
        '&:hover': {
            cursor: 'pointer'
        },
    },
    mainBar1: {

    }
}));

export default useStyles