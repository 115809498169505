import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [bottomNav, setBottomNav] = useState({ activeTab: 0, status: '' });

    return (
        <DataContext.Provider value={{ bottomNav, setBottomNav }}>
            {children}
        </DataContext.Provider>
    );
};

export const useBottomNav = () => useContext(DataContext);