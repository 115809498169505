import React, { useState, useEffect, useRef } from 'react';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { Image } from 'components/atoms';
import Logo from 'assets/images/logo.png'
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Button, TextField } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles'
import axiosInstance from 'services/axiosInstance';

const Otp = () => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()]
    const [progress, setProgress] = useState(false)
    const [response, setResponse] = useState({ status: "", msg: "" })
    const navigate = useNavigate()

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });
    const classes = useStyles();

    const handleChange = (index, value) => {
        if (value.length === 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== '' && index < inputRefs.length - 1) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleBackspace = (index, e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
            const newOtp = [...otp];
            if (index >= 0 && newOtp[index] !== '') {
                newOtp[index] = '';
                setOtp(newOtp);
                if (index > 0) {
                    inputRefs[index - 1].current.focus();
                } else {
                    inputRefs[0].current.focus();
                }
            } else if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        }
    };

    const handleSubmit = event => {
        event.preventDefault();

        if (formState.isValid) {
            window.location.replace('/');
        }

        setFormState(formState => ({
            ...formState,
            touched: {
                ...formState.touched,
                ...formState.errors,
            },
        }));
    };

    useEffect(() => {
        const query = localStorage.getItem('query')
        const agentId = localStorage.getItem('relatedAgentC')
        const phoneNumber = localStorage.getItem('phoneNumber')
        if (query === null || agentId === null || phoneNumber === null) {
            navigate('/')
        }
        inputRefs[0].current.focus();
    }, []);


    const validateOTP = async (e) => {
        e.preventDefault()
        try {
            setProgress(true)
            const otpCode = parseInt(otp.join(''), 10);
            const response = await axiosInstance.post(`agent/login`, { phoneNumber: localStorage.getItem('phoneNumber'), code: otpCode, lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') });
            if (response.status === 200) {
                let data = response.data.body
                let headers = response.data.headers
                localStorage.setItem("token", data.token)
                localStorage.setItem("isLogin", data.isLogin)
                localStorage.setItem("isActive", data.isActive)
                localStorage.setItem("refreshToken", data.refreshToken)
                localStorage.setItem("agentId", data.agentId)
                localStorage.setItem("name", data.name)
                localStorage.setItem("employeeIdNumber", data.employeeIdNumber)
                localStorage.setItem("primaryRole", data.primaryRole)
                axiosInstance.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
                setResponse({ status: 'success', msg: headers.status_message })
                navigate('/home')
            } else {
                let headers = response.data.headers
                setResponse({ status: 'error', msg: headers.status_message })
                setProgress(false)
            }
        } catch (error) {
            setProgress(false)
            setResponse({ status: 'error', msg: error.headers.status_message })
        }
    }

    return (
        <>
            <div className={classes.root}>
                <Section className={classes.section}>
                    <div className={classes.formContainer}>
                        <Image
                            className={classes.logoImage}
                            src={Logo}
                            alt="Sales app logo"
                            lazy={true} />
                        <SectionHeader
                            title="Welcome to Sales Portal ✋🏾"
                            subtitle="Enter Your OTP Code to Continue"
                            titleProps={{
                                variant: 'h5',
                            }}
                            subtitleProps={{
                                variant: 'body1'
                            }}
                            border={{
                                variant: '1px solid red'
                            }}
                            style={{
                                marginTop: '15px'
                            }}
                        />

                        <div className={classes.root}>
                            {response.status !== "" && <Alert severity={response.status} style={{ marginBottom: '8px', fontSize: '12px' }}>{response.msg}!</Alert>}
                            <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3} justifyContent="center" display="flex">
                                            {otp.map((value, index) => (
                                                <Grid item xs={3} key={index}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        size='medium'
                                                        inputRef={inputRefs[index]}
                                                        maxLength={1}
                                                        inputProps={{
                                                            maxLength: 1,
                                                            style: {
                                                                textAlign: 'center',
                                                                fontSize: 20,
                                                            },
                                                        }}
                                                        value={value}
                                                        onChange={(e) => handleChange(index, e.target.value)}
                                                        onKeyDown={(e) => handleBackspace(index, e)}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                                        {progress === false && <Button
                                            disableElevation
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            className={classes.submitButton}
                                            onClick={(e) => validateOTP(e)}
                                            disabled={otp[0] !== "" && otp[1] !== "" && otp[2] !== "" && otp[3] !== "" ? false : true}
                                            fullWidth>
                                            Login
                                        </Button>
                                        }
                                        {progress && <LoadingButton fullWidth size='large' loading variant="outlined">
                                            Submit
                                        </LoadingButton>}
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Section>
            </div>
        </>
    );
};

export default Otp;
