import React, { useEffect, useState } from 'react';
import { Grid, Typography, FormControlLabel, Button, MenuItem, Radio, RadioGroup, FormControl, FormLabel, TextField } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useStyles from './styles';
import LoadingButton from '@mui/lab/LoadingButton';
import axiosInstance from 'services/axiosInstance';
import moment from 'moment'
import dayjs from 'dayjs';
import { base54Decode } from 'helpers/base54Utils';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import Loader from 'components/Loader/Loader';

const dataDummy = [{
    answer_type: 'text',
    question: 'Phone number',
    tkey: 'mobilePhone',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'text',
    question: 'First name',
    tkey: 'firstName',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'text',
    question: 'Last name',
    tkey: 'lastName',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'text',
    question: 'National ID',
    tkey: 'idNumber',
    is_required: false,
    helperText: 'Optional for the Lead Registration'
}, {
    answer_type: 'text',
    question: 'Location',
    tkey: 'location',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'dropdown',
    question: 'Payment method',
    tkey: 'paymentMethod',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'CASH',
        answer: 'CASH'
    }, {
        tkey: 'PAYG',
        answer: 'PAYG'
    }]
},
{
    answer_type: 'dropdown',
    question: 'Select Product',
    tkey: 'productInterested',
    is_required: true,
    helperText: '',
    answers: []
},
{
    answer_type: 'dropdown',
    question: 'Purchase Date',
    tkey: 'purchaseDate',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'Now',
        answer: 'Now'
    }, {
        tkey: 'Two Weeks',
        answer: 'Two Weeks'
    }, {
        tkey: 'Two Months',
        answer: 'Two Months'
    }, {
        tkey: 'Later',
        answer: 'Later'
    }]
},
{
    answer_type: 'dropdown',
    question: 'Gender',
    tkey: 'gender',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'Male',
        answer: 'Male'
    }, {
        tkey: 'Female',
        answer: 'Female'
    }]
}, {
    answer_type: 'dropdown',
    question: 'Lead Source',
    tkey: 'leadSource',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'Chama',
        answer: 'Chama'
    }, {
        tkey: 'Door to Door',
        answer: 'Door to Door'
    }, {
        tkey: 'Market Storm',
        answer: 'Market Storm'
    }, {
        tkey: 'Sacco',
        answer: 'Sacco'
    }, {
        tkey: 'Facebook',
        answer: 'Facebook'
    }, {
        tkey: 'Radio',
        answer: 'Radio'
    }, {
        tkey: 'Online',
        answer: 'Online'
    }, {
        tkey: 'Walk-In',
        answer: 'Walk-In'
    }, {
        tkey: 'Print',
        answer: 'Print'
    }, {
        tkey: 'TV',
        answer: 'TV'
    }, {
        tkey: 'Twitter/X',
        answer: 'Twitter/X'
    }, {
        tkey: 'LinkedIn',
        answer: 'LinkedIn'
    }, {
        tkey: 'Instagram',
        answer: 'Instagram'
    }, {
        tkey: 'Whatsapp',
        answer: 'Whatsapp'
    }, {
        tkey: 'Website',
        answer: 'Website'
    }, {
        tkey: 'Demo Event',
        answer: 'Demo Event'
    }, {
        tkey: 'Field Day',
        answer: 'Field Day'
    }]
}, {
    answer_type: 'dropdown',
    question: 'Preferred Language',
    tkey: 'preferredLanguage',
    is_required: true,
    helperText: '',
    answers: [{
        tkey: 'English',
        answer: 'English'
    }, {
        tkey: 'Swahili',
        answer: 'Swahili'
    }]
}, {
    answer_type: 'dropdown',
    question: 'Water Source',
    tkey: 'waterSource',
    is_required: true,
    helperText: '',
    answers: []
}, {
    answer_type: 'number',
    question: 'Water Source Distance(Metres)',
    tkey: 'waterSourceDistance',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'number',
    question: 'Land Size in Acreage',
    tkey: 'acreage',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'number',
    question: 'Daily Water Usage(Litres)',
    tkey: 'dailyWaterUsage',
    is_required: true,
    helperText: ''
}, {
    answer_type: 'number',
    question: 'Total Dynamic head(Mtrs)',
    tkey: 'totalDynamicHead',
    is_required: true,
    helperText: ''
}]

const LeadReg = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({});
    const [progress, setProgress] = useState(false)
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        setData(dataDummy)
        fetchWaterSources()
    }, [])

    const validateForm = () => {
        return data.every((field) => {
            return !field.is_required || (formValues[field.tkey] && formValues[field.tkey] !== '');
        });
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [formValues, data]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params = queryParams.get('params')
        if (params !== null) {
            const decodedText = base54Decode(params)
            const keyValuePairs = decodedText.split("&&");
            const values = {};
            keyValuePairs.forEach(pair => {
                pair = pair.replace(/\0/g, '');
                const [key, value] = pair.split("=").map(str => str.trim());
                values[key] = value;
            });
            const mobilePhone = values['mobilePhone'];
            const agentId = values['agentId']
            fetchLeadInfomation(agentId, mobilePhone)
        }
    }, [location.search]);

    const fetchLeadInfomation = async (agentId, mobilePhone) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`leads/details?mobilePhone=${mobilePhone}&&agentId=${agentId}`);
            setLoading(false)
            const listAvoid = ["status", "name", "leadStatus", "attributes"]
            if (response.status === 200) {
                let records = response.data.body
                if (records.length > 0) {
                    const obj = records[0];
                    Object.entries(obj).forEach(([key, value]) => {
                        setFormValues(prevValues => {
                            const updatedValues = { ...prevValues };
                            if (listAvoid.includes(key) === false && value !== null) {
                                updatedValues[key] = value;
                            }
                            return updatedValues;
                        });

                        if (key === 'paymentMethod') {
                            if (value === "CASH" || value === "PAYG") {
                                fetchProducts(value)
                            }
                        }
                    });
                    setResponse({ status: '', msg: '' })
                } else {
                    setResponse({ status: 'error', msg: 'We are unable to fetch the records for that lead. Please try again!' })
                }
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
            setTimeout(() => {
                if (error.headers.status_code === 401) {
                    localStorage.clear()
                    navigate("/login")
                }
            }, 1000)
        }
    }

    const handleInputChange = (event, tkey) => {
        const { value } = event.target;
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (value === "") {
                delete updatedValues[tkey];
            } else {
                updatedValues[tkey] = value;
            }
            return updatedValues;
        });
        if (tkey === 'paymentMethod') {
            fetchProducts(value)
        }
    }

    const handleInputChange2 = (event, tkey) => {
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: dayjs(event) }));
    }

    const fetchProducts = async (value) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`products?paymentType=${value}`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                dataDummy[6].answers = records
                setData(dataDummy)
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            if (error.headers.status_code === 401) {
                localStorage.clear()
                navigate('/')
            } else {
                setResponse({ status: "error", msg: error.headers.status_message })
            }
        }
    }

    const fetchWaterSources = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`watersources`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                dataDummy[11].answers = records
                setData(dataDummy)
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            if (error.headers.status_code === 401) {
                localStorage.clear()
                navigate('/')
            } else {
                setResponse({ status: "error", msg: error.headers.status_message })
            }
        }
    }

    const handleSubmit = async () => {
        if (formValues.id === undefined) {
            leadRegistration()
        } else {
            leadUpdate()
        }
    }

    const leadRegistration = async () => {
        setProgress(true)
        Object.assign(formValues, { lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') })
        try {
            const response = await axiosInstance.post('leads', formValues);
            if (response.status === 200) {
                setProgress(false)
                setResponse({ status: 'success', msg: 'New Lead has been created!' })
                setTimeout(() => {
                    navigate('/home')
                }, 2000)
            } else {
                setProgress(false)
                let responseError = response.data
                setResponse({ status: 'error', msg: responseError.headers.status_message })
            }
        } catch (error) {
            setProgress(false)
            setResponse({ status: 'error', msg: error.headers.status_message })
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const leadUpdate = async () => {
        setProgress(true)
        Object.assign(formValues, { lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') })
        try {
            const response = await axiosInstance.put(`leads`, formValues);
            if (response.status === 200) {
                setProgress(false)
                setResponse({ status: 'success', msg: 'A Lead has been updated successful!' })
                setTimeout(() => {
                    navigate('/home')
                }, 2000)
            } else {
                setProgress(false)
                let responseError = response.data
                setResponse({ status: 'error', msg: responseError.headers.status_message })
            }
        } catch (error) {
            setProgress(false)
            setResponse({ status: 'error', msg: error.headers.status_message })
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={classes.root}>
            {loading && <Loader />}
            <div className={classes.shape}>
                <Box component="main" sx={{ p: 3, mb: 0, mt: 0, position: 'relative', width: '100%', }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        direction='row'>
                        <Grid
                            item
                            xs={4}
                            onClick={() => navigate('/home')}
                            md={6}
                            data-aos={'fade-up'}
                            style={{ display: 'flex', color: 'white' }}>
                            <ArrowBackIosIcon className={classes.backArrow} /> <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            md={6}
                            data-aos={'fade-up'}>
                            <Typography variant='h6' className={classes.mainTitle}>Lead Registration</Typography>
                        </Grid>

                    </Grid>
                </Box>
            </div>
            <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>
                {response.status !== '' && <Alert severity={response.status} style={{ marginBottom: '8px', marginTop: '6px', display: 'flex', alignItems: 'center', fontSize: '12px' }}>{response.msg}</Alert>}
                <Grid container spacing={0} style={{ marginTop: 8, padding: '10px' }}>
                    {data.map((field, index) => {
                        switch (field.answer_type) {
                            case 'text':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '-6px' : '8px' }}>
                                        <TextField
                                            margin="normal"
                                            required={field.is_required}
                                            size="medium"
                                            fullWidth
                                            label={field.question}
                                            variant='outlined'
                                            value={formValues[field.tkey] || ''}
                                            onChange={e => handleInputChange(e, field.tkey)}
                                            name={field.tkey}
                                            type="text"
                                            helperText={field.helperText}
                                            id={field.tkey}
                                        />
                                    </Grid>
                                );
                            case 'date':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '-6px' : '8px' }}>
                                        <FormControl fullWidth component="fieldset">
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={moment().format('YYYY-MM-DD')} style={{ border: '1px solid lightgray' }}>
                                                <DatePicker
                                                    style={{ border: '1px solid lightgray' }}
                                                    fullWidth
                                                    localeText={{ toolbarTitle: 'Select Date of Birth' }}
                                                    label={field.question}

                                                    value={formValues[field.tkey] || ''}
                                                    onChange={(e) => handleInputChange2(e, field.tkey)}
                                                    slotProps={{
                                                        fieldSeparator: { children: 'to' },
                                                        textField: {
                                                            variant: 'outlined',
                                                            helperText: `${field.helperText}`
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                )
                            case 'number':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '-6px' : '8px' }}>
                                        <FormControl fullWidth component="fieldset">
                                            <TextField
                                                margin="normal"
                                                required={field.is_required}
                                                size="medium"
                                                fullWidth
                                                helperText={field.helperText}
                                                label={field.question}
                                                variant='outlined'
                                                value={formValues[field.tkey] || ''}
                                                onChange={e => handleInputChange(e, field.tkey)}
                                                name={field.tkey}
                                                type="number"
                                                id={field.tkey}
                                                InputProps={{
                                                    inputProps: {
                                                        border: '1px solid green',
                                                        min: 0,
                                                        max: 100
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                );
                            case 'dropdown':
                                return (
                                    field.answers.length > 0 && <Grid item xs={12} md={12} key={field.tkey} style={{ marginTop: index === 0 ? '-5px' : '8px' }}>
                                        {field.tkey === 'productInterested' && loading === true && <LinearProgress style={{ height: '2px', marginBottom: '-6px' }} />}
                                        <FormControl fullWidth component="fieldset">
                                            <TextField
                                                fullWidth
                                                id={field.tkey}
                                                select
                                                required={field.is_required}
                                                size="medium"
                                                label={`${field.question}`}
                                                variant='outlined'
                                                margin='normal'
                                                helperText={field.helperText}
                                                value={formValues[field.tkey] || ''}
                                                onChange={e => handleInputChange(e, field.tkey)}>
                                                {field.answers.map((option, index) => (
                                                    <MenuItem id={option.tkey} key={index} value={option.tkey}>
                                                        {option.answer}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                );
                            case 'radio':
                                return (
                                    <Grid alignItems="center" container spacing={2} style={{ marginTop: '8px' }} key={field.tkey}>
                                        <Grid item xs={12} md={5}>
                                            <FormControl fullWidth>
                                                <FormLabel id="_animal_label" className='label1'>{field.question} {field.is_required === true && <span style={{ color: 'red' }}>*</span>} {field.is_required === false && <span style={{ color: 'gray' }}>[Optional]</span>}</FormLabel>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <FormControl fullWidth>
                                                <RadioGroup
                                                    fullWidth
                                                    row
                                                    aria-labelledby="label_animal_01"
                                                    defaultValue="female"
                                                    name="label_animal_02">
                                                    {field.answers.map((option, i) => (
                                                        <FormControlLabel key={i} value={option.answer} control={<Radio checked={formValues[field.tkey] === option.answer} value={option.answer} onChange={e => handleInputChange(e, field.tkey)} />} label={option.answer} />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                );

                            default:
                                return null;
                        }
                    })}

                    <Grid item xs={12} md={12}>
                        {progress === false && <Button disableElevation variant="contained" fullWidth color='primary' onClick={handleSubmit} style={{ marginTop: '25px', color: 'white' }}
                            disabled={!isFormValid}>Submit</Button>}

                        {progress && <LoadingButton fullWidth size='large' loading variant="outlined">
                            Submit
                        </LoadingButton>}
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
};

export default LeadReg;
