import { makeStyles, } from '@material-ui/core/styles';
import BG_TOP from 'assets/images/bg_top.png'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto',
        height: '100vh'
    },
    pagePaddingTop: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    mainTitle: {
        lineHeight: '24px',
        fontSize: '17px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        color: 'white'
    },
    shape: {
        backgroundImage: `url(${BG_TOP})`,
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundSize: 'cover',
        height: '10%',
        backgroundPosition: 'center',
        borderBottom: 'none',
        display: 'flex',
        alignContent: 'center'
    },
    backArrow: {
        padding: '0px 0px',
        margin: '1px 1px',
        lineHeight: '24px',
        fontSize: '17px',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    sectionNoPadding: {
        paddingTop: theme.spacing(0),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(0),
        },
        backgroundColor: '#FFF'
    },

    datePickerRoot: {
        width: '100%',
        '& .MuiFormControl-root': {
            border: '1px solid #ced4da', // Set your desired border style here
            borderRadius: theme.shape.borderRadius, // Optional: Set border radius
            '&:hover': {
                border: '1px solid #ced4da', // Optional: Border color on hover
            },
        },
    }
}));

export default useStyles
