import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import SpeedIcon from '@mui/icons-material/Speed';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useBottomNav } from 'context/DataContext';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
    Dashboard as DashboardView,
    Notices as NoticesView,
    Tasks as TasksView,
    Sales as SalesView,
    Fee as FeeView
} from 'views'

const NavBottom = () => {
    const [value, setValue] = useState(0);
    const ref = useRef(null);
    const classes = useStyles();
    const { bottomNav, setBottomNav } = useBottomNav()


    useEffect(() => {
        if (localStorage.getItem('activeTab') !== null) {
            setValue(parseInt(localStorage.getItem('activeTab')))
        }
        if (parseInt(bottomNav.activeTab) === 1) {
            setValue(parseInt(bottomNav.activeTab))
        }
    }, [bottomNav])

    const renderContent = () => {
        switch (value) {
            case 0:
                return <DashboardView />;
            case 1:
                return <SalesView />;
            // case 2:
            //     return <FeeView />;
            case 2:
                return <TasksView />;
            case 3:
                return <NoticesView />;
            default:
                return <DashboardView />;
        }
    };


    return (
        <div
            className={clsx({
                [classes.root]: true,
            })}>

            {renderContent()}
            <Box sx={{ pb: 3 }} ref={ref}>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100vw', zIndex: 1000 }} elevation={2}>
                    <BottomNavigation
                        style={{ backgroundColor: '#fcfcfc' }}
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            localStorage.setItem('activeTab', newValue)
                            setValue(newValue);
                            setBottomNav({ activeTab: newValue, status: null })
                        }}>
                        <BottomNavigationAction label="Dashboard" icon={<SpeedIcon />} classes={{ label: classes.label }} />
                        <BottomNavigationAction label="Sales" icon={<PeopleAltIcon />} classes={{ label: classes.label }} />
                        {/* <BottomNavigationAction label="Commission" icon={<AttachMoneyIcon />} classes={{ label: classes.label }} /> */}
                        <BottomNavigationAction label="Tasks" icon={<TaskAltIcon />} classes={{ label: classes.label }} />
                        <BottomNavigationAction label="Notices" icon={<NotificationsNoneIcon />} classes={{ label: classes.label }} />
                    </BottomNavigation>
                </Paper>
            </Box>
        </div>
    );
};

NavBottom.propTypes = {
    children: PropTypes.node,
};

export default NavBottom;
