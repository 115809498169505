import { makeStyles } from '@material-ui/core/styles';
import BG_TOP from 'assets/images/bg_top.png'
const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto'
    },
    shape: {
        backgroundImage: `url(${BG_TOP})`,
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderBottom: 'none',
        display: 'flex',
        alignContent: 'center'
    },
    pagePaddingTop: {
        borderBottom: 'none',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    sectionNoPadding: {
        paddingTop: 0,
        backgroundColor: '#F6F6F6'
    },
    shape1: {
        background: '#FFF',
        borderBottomRightRadius: '20%',
        borderBottomLeftRadius: '20%',
        borderBottom: 'none'
    },
    fabcontainer: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    paginationcontainer: {
        bottom: theme.spacing(1),
        right: theme.spacing(1)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(9),
        right: theme.spacing(1),
        float: 'right',
        zIndex: 1000,
        backgroundColor: '#07B282 !important',
        color: 'white !important'
    },
    gridLead: {
        borderRadius: '12px',
        height: '140px',
        backgroundColor: '#F3F2F1',
        padding: '10px 12px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '6px 1px',
        '&:hover': {
            cursor: 'pointer',
        },
    }
}));

export default useStyles