import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    sectionNoPadding: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(1),
        },
        backgroundColor: '#F6F6F6'
    },
    tasks: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 12px',
        backgroundColor: '#FFF'
    },
    tasks1: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 10px',
        border: '1px dotted lightgray'
    },

}));

export default useStyles