import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, FormControl, FormLabel, TextField, MenuItem } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import axiosInstance from 'services/axiosInstance';
import { base54Decode, base54Encode } from 'helpers/base54Utils';
import { Box } from '@mui/material';

const TagRequest = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [agents, setAgents] = useState([])
    const [formValues, setFormValues] = useState({});
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [status, setStatus] = useState("")
    const [msg, setMsg] = useState("")
    const [length, setLength] = useState(0)
    const [locals, setLocals] = useState({ agentId: "", leadId: "", accountRef: "", mobilePhone: "" })


    const handleInputChange = (event, tkey) => {
        const { value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: value }));
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params = queryParams.get('params')
        const decodedText = base54Decode(params)
        const keyValuePairs = decodedText.split("&&");
        const values = {};
        keyValuePairs.forEach(pair => {
            pair = pair.replace(/\0/g, '');
            const [key, value] = pair.split("=").map(str => str.trim());
            values[key] = value;
        });
        const leadId = values['leadId'];
        const accountRef = values['accountRef'];
        const mobilePhone = values['mobilePhone'];
        const agentId = values['agentId']
        setLocals({ agentId: agentId, leadId: leadId, accountRef: accountRef, mobilePhone: mobilePhone })
        fetchAgents()
    }, [location.search]);

    const fetchAgents = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`agents`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                console.log(records)
                setAgents(records)
                setResponse({ status: '', msg: '' })
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
            setTimeout(() => {
                if (error.headers.status_code === 401) {
                    localStorage.clear()
                    navigate("/login")
                }
            }, 1000)
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            Object.assign(formValues, { "leadId": locals.leadId, lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') })
            const response = await axiosInstance.post('lead/tagging', formValues);
            if (response.status === 200) {
                setMsg('Lead Tagging request has been created!')
                setStatus("success")
                setTimeout(() => {
                    let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                    const encodedText = base54Encode(params)
                    navigate(`/view/lead-detail?params=${encodedText}`)
                }, 2000)
            } else {
                setMsg(response.data.headers.status_message)
                setStatus("error")
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error);
            setMsg(error.headers.status_message)
            setStatus("error")
        }
        setResponse(true)
    }

    return (
        <div className={classes.root}>
            <div className={classes.shape}>
                <Box component="main" sx={{ p: 3, mb: 0, mt: 0, position: 'relative', width: '100%' }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        direction='row'>
                        <Grid
                            item
                            xs={4}
                            onClick={() => {
                                let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                                const encodedText = base54Encode(params)
                                navigate(`/view/lead-detail?params=${encodedText}`)
                            }}
                            md={6}
                            data-aos={'fade-up'}
                            style={{ display: 'flex', color: 'white' }}>
                            <ArrowBackIosIcon className={classes.backArrow} /> <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            md={6}
                            data-aos={'fade-up'}>
                            <Typography variant='h6' className={classes.mainTitle}>Lead Tag Request</Typography>
                        </Grid>

                    </Grid>
                </Box>
            </div>
            <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>
                <Grid container spacing={2} style={{ marginTop: 2, padding: '10px' }}>
                    {response.status !== "" && <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Alert severity={status} style={{ marginBottom: '6px', display: 'flex', alignItems: 'center', fontSize: '10px', width: '100%' }}>{msg}</Alert>
                    </Grid>}


                    <Grid item xs={12} md={12} key={'taggedAgentId'}>
                        <FormControl fullWidth component="fieldset">
                            <FormLabel component="legend" className='label1'>Agent To Be Tagged  <span style={{ color: 'red' }}>*</span> </FormLabel>
                            <TextField
                                fullWidth
                                id={'taggedAgentId'}
                                select
                                required
                                size="medium"
                                variant='outlined'
                                margin='normal'
                                value={formValues['taggedAgentId'] || ''}
                                onChange={e => handleInputChange(e, "taggedAgentId")}>
                                <MenuItem disabled id="" key={'index'} value="" selected={false}>Select Agent To Tagged</MenuItem>
                                {agents.map((option, index) => (
                                    <MenuItem id={option.agentId} key={index} value={option.agentId}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>



                    <Grid item xs={12} md={12} key={`description`} style={{ marginTop: '10px' }}>
                        <FormControl fullWidth component="fieldset">
                            <FormLabel component="legend" className='label1'>Tagging Comments  <span style={{ color: 'red' }}>*</span> </FormLabel>
                            <TextField
                                margin="normal"
                                required
                                size="small"
                                fullWidth
                                error={length > 200 ? true : false}
                                multiline
                                minRows={5}
                                maxRows={8}
                                variant='outlined'
                                helperText={`${length}/200`}
                                value={formValues['tagComments'] || ''}
                                onChange={e => {
                                    setLength(e.target.value.length)
                                    handleInputChange(e, 'tagComments')
                                }}
                                name={`tagComments`}
                                type="text"
                                id={`tagComments`}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {loading === false && <Button variant="contained" disableElevation fullWidth color='primary' onClick={handleSubmit} style={{ marginTop: '15px', color: 'white' }}
                            disabled={
                                Object.keys(formValues).length >= 2 && length <= 200 ? false : true
                            }>Submit</Button>
                        }

                        {loading && <LoadingButton fullWidth size='large' loading variant="outlined">
                            Submit
                        </LoadingButton>}
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default TagRequest;
