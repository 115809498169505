import { makeStyles } from '@material-ui/core/styles';
import BG_TOP from 'assets/images/bg_top.png'
const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'Poppins',
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto',
    },
    shape: {
        backgroundImage: `url(${BG_TOP})`,
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderBottom: 'none',
        display: 'flex',
        alignContent: 'center'
    },
    pagePaddingTop: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    sectionNoPadding: {
        paddingTop: theme.spacing(0),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(0),
        },
        backgroundColor: '#FFF'
    },
}));

export default useStyles