import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    logoImage: {
        width: '132px',
        height: '48px',
    },
    submitButton: {
        '&:hover': {
            backgroundColor: '#00CCC8',
            cursor: 'pointer'
        },
    }
}));

export default useStyles