import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, FormControl, FormLabel, TextField } from '@material-ui/core';
import { Section } from 'components/organisms';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import axiosInstance from 'services/axiosInstance';
import { base54Decode, base54Encode } from 'helpers/base54Utils';
import { Box } from '@mui/material';

const NewTask = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({});
    const location = useLocation();
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(false)
    const [status, setStatus] = useState("")
    const [msg, setMsg] = useState("")
    const [length, setLength] = useState(0)
    const [locals, setLocals] = useState({ agentId: "", leadId: "", accountRef: "", mobilePhone: "" })

    const handleInputChange2 = (event, tkey) => {
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: dayjs(event) }));
    }

    const handleInputChange = (event, tkey) => {
        const { _id, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: value }));
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params = queryParams.get('params')
        const decodedText = base54Decode(params)
        const keyValuePairs = decodedText.split("&&");
        const values = {};
        keyValuePairs.forEach(pair => {
            pair = pair.replace(/\0/g, '');
            const [key, value] = pair.split("=").map(str => str.trim());
            values[key] = value;
        });
        const leadId = values['leadId'];
        const accountRef = values['accountRef'];
        const mobilePhone = values['mobilePhone'];
        const name = values['name']
        const agentId = values['agentId']
        setName(name)
        setLocals({ agentId: agentId, leadId: leadId, accountRef: accountRef, mobilePhone: mobilePhone })
    }, [location.search]);

    const handleSubmit = async () => {
        setLoading(true)
        try {
            Object.assign(formValues, { assignedByAgent: locals.agentId, createdByAgent: localStorage.getItem('relatedAgentC'), "relatedLead": locals.leadId, lat: localStorage.getItem('lat'), lng: localStorage.getItem('lng') })
            const response = await axiosInstance.post('tasks', formValues);
            if (response.status === 200) {
                setMsg('New Task has been created!')
                setStatus("success")
                setTimeout(() => {
                    let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                    const encodedText = base54Encode(params)
                    navigate(`/view/lead-detail?params=${encodedText}`)
                }, 2000)
            } else {
                setMsg(response.data.headers.status_message)
                setStatus("error")
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error);
            setMsg(error.headers.status_message)
            setStatus("error")
        }
        setResponse(true)
    }

    return (
        <div className={classes.root}>
            <div className={classes.shape}>
                <Box component="main" sx={{ p: 3, mb: 0, mt: 0, position: 'relative', width: '100%' }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        direction='row'>
                        <Grid
                            item
                            xs={4}
                            onClick={() => {
                                let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                                const encodedText = base54Encode(params)
                                navigate(`/view/lead-detail?params=${encodedText}`)
                            }}
                            md={6}
                            data-aos={'fade-up'}
                            style={{ display: 'flex', color: 'white' }}>
                            <ArrowBackIosIcon className={classes.backArrow} /> <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            md={6}
                            data-aos={'fade-up'}>
                            <Typography variant='h6' className={classes.mainTitle}>New Task</Typography>
                        </Grid>

                    </Grid>
                </Box>
            </div>
            <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>
                <Grid container spacing={2} style={{ marginTop: 0, padding: '10px' }}>
                    {response && <Grid item xs={12} md={12}>
                        <Alert severity={status} style={{ marginBottom: '6px' }}>{msg}!</Alert>
                    </Grid>}

                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='h6'>{name}</Typography>
                    </Grid>

                    <Grid item xs={12} md={12} key={`description`} style={{ marginTop: '10px' }}>
                        <FormControl fullWidth component="fieldset">
                            <FormLabel component="legend" className='label1'>Task description  <span style={{ color: 'red' }}>*</span> </FormLabel>
                            <TextField
                                margin="normal"
                                required
                                size="small"
                                fullWidth
                                error={length > 200 ? true : false}
                                multiline
                                minRows={5}
                                maxRows={8}
                                variant='outlined'
                                helperText={`${length}/200`}
                                value={formValues['description'] || ''}
                                onChange={e => {
                                    setLength(e.target.value.length)
                                    handleInputChange(e, 'description')
                                }}
                                name={`description`}
                                type="text"
                                id={`description`}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} key="_dd" style={{ marginTop: '15px' }}>
                        <FormControl fullWidth component="fieldset" className={classes.datePickerRoot}>
                            <FormLabel component="legend" style={{ marginBottom: '16px' }}>Task Start Date <span style={{ marginBottom: '25px' }}>*</span></FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    fullWidth
                                    inputFormat="MM/DD/YYYY"
                                    closeOnSelect={true}
                                    disablePast={true}
                                    showToolbar={true}
                                    toolbarTitle="Select Date Of Birth"
                                    value={formValues['taskDate']}
                                    onChange={e => handleInputChange2(e, 'taskDate')}
                                    slotProps={{
                                        fieldSeparator: { children: 'to' },
                                        textField: {
                                            variant: 'outlined'
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {loading === false && <Button variant="contained" disableElevation fullWidth color='primary' onClick={handleSubmit} style={{ marginTop: '25px', color: 'white' }}
                            disabled={
                                Object.keys(formValues).length >= 2 && length <= 200 ? false : true
                            }>Submit</Button>
                        }

                        {loading && <LoadingButton fullWidth size='large' loading variant="outlined">
                            Submit
                        </LoadingButton>}
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default NewTask;
