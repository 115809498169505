import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { Chip, Grid, Typography, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import PENDING_ICON from 'assets/icons/pending-icon.png'
import EDIT_ICON from 'assets/icons/edit-icon.png'
import DONE_ICON from 'assets/icons/done-icon.png'
import ACTION_ICON from 'assets/icons/action-icon.png'
import PAYMENT_ICON from "assets/icons/payment-icon.png"
import { useLocation } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import EmptyState from 'views/EmptyState';
import axiosInstance from 'services/axiosInstance';
import TaskStepper from './TaskStepper';
import moment from 'moment'
import { base54Decode, base54Encode } from 'helpers/base54Utils';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Loader from 'components/Loader/Loader';

const LeadDetail = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const location = useLocation();
    const [leads, setLeads] = useState([])
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({ status: '', msg: '' })
    const [tasks, setTasks] = useState([])
    const [details, setDetails] = useState([])
    const [locals, setLocals] = useState({ leadId: "", accountRef: "", mobilePhone: "", agentId: "" })

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params = queryParams.get('params')
        const decodedText = base54Decode(params)
        if (!decodedText) {
            console.error("Decoded text is invalid or empty.");
            return;
        }
        const values = decodedText
            .split("&&")
            .map(pair => pair.replace(/\0/g, '').split("=").map(str => str.trim()))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});


        const { leadId, accountRef, mobilePhone, agentId } = values;
        setLocals({ agentId, leadId, accountRef, mobilePhone });

        if (agentId && leadId && mobilePhone && accountRef) {
            fetchDataFromMultipleApis(agentId, leadId, mobilePhone, accountRef)
        }
    }, [location.search]);

    const makeApiCall = async (endpoint) => {
        try {
            const response = await axiosInstance.get(endpoint);
            if (response.status === 200) {
                return response.data.body;
            } else {
                setResponse({ msg: response.data.headers.status_message, status: 'error' })
                return;
            }

        } catch (error) {
            console.error(error)
            return;
        }
    }

    const fetchDataFromMultipleApis = async (currentAgentId, leadId, mobilePhone, accountRef) => {
        setLoading(true)
        try {
            let agentId = localStorage.getItem("relatedAgentC")
            const leadDetailPromise = makeApiCall(`leads/details?mobilePhone=${mobilePhone}&&agentId=${agentId}`);
            const datamartPromise = makeApiCall(`lead/datamart?mobilePhone=${mobilePhone}`);
            const taskPromise = makeApiCall(`tasks?type=QUERY&leadId=${leadId}&agentId=${agentId}`)

            const [leadDetailData, datamartData, taskData] = await Promise.all([
                leadDetailPromise,
                datamartPromise,
                taskPromise
            ])

            if (typeof datamartData !== "undefined") {
                setLeads(leadDetailData)
                setTasks(taskData)

                let params = `agentId=${currentAgentId}&&leadId=${leadId}&&accountRef=${accountRef}&&mobilePhone=${mobilePhone}`
                const encodedText = base54Encode(params)

                const detail = [{
                    type: 'item',
                    title: 'Tag a Lead',
                    date: datamartData.length >= 1 ? (datamartData[0].taggingStatus === null ? null : datamartData[0].taggingStatus) : null,
                    callToAction: `/tag-request?params=${encodedText}`,
                    icon: ACTION_ICON,
                    label: leadDetailData[0].agentId === localStorage.getItem('relatedAgentC') ? 'Required your action' : 'Requires agent action'
                }, {
                    type: 'item',
                    title: 'KYC done',
                    date: datamartData.length >= 1 ? (datamartData[0].kycDoneDate === null ? null : moment(datamartData[0].kycDoneDate).format('YYYY-MM-DD')) : null,
                    callToAction: `/view/kyc?params=${encodedText}`,
                    icon: ACTION_ICON,
                    label: leadDetailData[0].agentId === localStorage.getItem('relatedAgentC') ? 'Required your action' : 'Requires agent action'
                }, {
                    type: 'item',
                    title: 'CDS 1 data collection',
                    date: datamartData.length >= 1 ? (datamartData[0].cds1CompletionDate === null ? null : moment(datamartData[0].cds1CompletionDate).format('YYYY-MM-DD')) : null,
                    callToAction: `/view/cds?params=${encodedText}`,
                    icon: ACTION_ICON,
                    label: leadDetailData[0].agentId === localStorage.getItem('relatedAgentC') ? 'Required your action' : 'Requires agent action'
                }, {
                    type: 'item',
                    title: 'Account creation',
                    date: datamartData.length >= 1 ? (datamartData[0].leadConvertedDate === null ? null : moment(datamartData[0].leadConvertedDate).format('YYYY-MM-DD')) : null,
                    callToAction: '',
                    icon: PENDING_ICON,
                    label: 'Pending'
                },
                {
                    type: 'banner',
                    title: 'Customer Deposit',
                    date: datamartData.length >= 1 ? (datamartData[0].leadConvertedDate === null ? null : moment(datamartData[0].leadConvertedDate).format('YYYY-MM-DD')) : null,
                    callToAction: '',
                    icon: PAYMENT_ICON,
                    label: ['Customer can only make payment after account creation', 'Customer can now make a payment']
                },
                {
                    type: 'item',
                    title: 'Credit Check (CDS2) (1/3)',
                    date: datamartData.length >= 1 ? (datamartData[0].cds2CompletionDate === null ? null : moment(datamartData[0].cds2CompletionDate).format('YYYY-MM-DD')) : null,
                    callToAction: '',
                    icon: PENDING_ICON,
                    label: 'Pending'
                },
                {
                    type: 'item',
                    title: 'Customer Deposit',
                    date: datamartData.length >= 1 ? (datamartData[0].depositDate === null ? null : moment(datamartData[0].depositDate).format('YYYY-MM-DD')) : null,
                    callToAction: '',
                    icon: PENDING_ICON,
                    label: 'Pending'
                }, {
                    type: 'item',
                    title: 'Installation (JSF) (2/2)',
                    date: datamartData.length >= 1 ? datamartData[0].jsfInstallationDate : null,
                    callToAction: '',
                    icon: PENDING_ICON,
                    label: 'Pending'
                }, {
                    type: 'item',
                    title: '1st month  of payment (3/3)',
                    date: datamartData.length >= 1 ? datamartData[0].jsfInstallationDate : null,
                    callToAction: '',
                    icon: PENDING_ICON,
                    label: 'Pending'
                }]
                setDetails(detail)
            }

            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
            setResponse({ msg: 'Request failed. Please try again', status: 'error' })
        }
    }

    const goToEditLead = (d) => {
        let params = `agentId=${d.agentId}&&mobilePhone=${d.mobilePhone.replace(/\s/g, '')}`
        const encodedText = base54Encode(params)
        navigate(`/view/lead-registration?params=${encodedText}`)
    }

    return (
        <>
            <div className={classes.root}>
                {loading && <Loader />}
                <div className={classes.shape}>
                    <Box component="main" sx={{ p: 3, mb: 0, mt: 0, lineHeight: '74px', width: '100%' }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems='center'
                            spacing={0}
                            direction='row'>
                            <Grid
                                item
                                xs={4}
                                onClick={() => navigate('/home')}
                                md={6}
                                data-aos={'fade-up'}
                                style={{ display: 'flex', color: 'white', alignItems: 'center', flexDirection: 'row' }}>
                                <ArrowBackIosIcon className={classes.backArrow} />
                                <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                md={6}
                                data-aos={'fade-up'}>
                                <Typography variant='h6' className={classes.mainTitle}>Lead Details</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </div>

                <Box component="main" sx={{ p: 3, mb: 3, mt: 2, width: '100%' }}>
                    {response.status && <Grid spacing={3} container>
                        <Grid item xs={12} md={12}>
                            <Alert severity={response.status} style={{ marginBottom: '6px', fontSize: '12px' }}>{response.msg}</Alert>
                        </Grid>
                    </Grid>}
                    <Grid container spacing={0}>
                        {loading === false && leads && leads.length > 0 &&
                            <Grid className={classes.gridLead} item xs={12} md={12}>
                                <div>
                                    <Typography variant="body1" style={{ fontFamily: 'Poppins', fontSize: '17px', fontWeight: 600 }} gutterBottom>{leads[0].name}</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Account ref: <b>{leads[0].idNumber === null ? '<<Missing>>' : leads[0].idNumber}</b>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Phone Number: <b>{leads[0].mobilePhone}</b>
                                    </Typography>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                        <Grid item xs={6} md={6} style={{ textAlign: 'start' }}>
                                            <Chip size='small' label={leads[0].leadStatus} />
                                        </Grid>

                                    </Grid>
                                </div>
                                <div>
                                    {localStorage.getItem('primaryRole') === 'Agent' && leads[0].agentId === localStorage.getItem('relatedAgentC') && <img src={EDIT_ICON} style={{ marginLeft: '-12px' }} onClick={() => goToEditLead(leads[0])} />}
                                    {localStorage.getItem('primaryRole') !== 'Agent' && <img src={EDIT_ICON} style={{ marginLeft: '-12px' }} onClick={() => goToEditLead(leads[0])} />}
                                </div>
                            </Grid>
                        }

                        {loading === true && <Grid item xs={12} md={12}>
                            <Skeleton variant="rectangular" width={'100%'} height={130} style={{ borderRadius: '12px' }} />
                        </Grid>}

                        {leads.length === 1 && <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }} className={classes.pagePaddingTop}>
                            {loading === false && leads.length === 1 && <Chip label={leads[0].paymentMethod === null ? "No Payment method" : leads[0].paymentMethod} color='primary' size='medium' style={{ borderRadius: '4px', marginRight: '10px' }} />}
                            {loading === true && <Skeleton variant="rectangular" width={'100%'} height={40} style={{ borderRadius: '12px' }} />}
                        </Grid>}
                    </Grid>
                    <Grid item style={{ marginTop: '20px' }}>
                        {details.length >= 1 && details.map((d, i) => {
                            switch (d.type) {
                                case 'item':
                                    return <Grid key={i} container spacing={0} className={classes.details} style={{ backgroundColor: 'white' }}>
                                        <Grid item xs={1} md={1}>
                                            {d.date === null && <img src={d.icon} />}
                                            {d.date !== null && <img src={DONE_ICON} />}
                                        </Grid>

                                        <Grid item xs={10} md={10}>
                                            <Typography variant='body1' className={classes.title}>{d.title}</Typography>
                                            <Typography variant='body2' className={classes.dateLabel}>{d.date === null ? d.label : d.date}</Typography>
                                        </Grid>

                                        {d.date === null && d.title !== 'KYC done' && (
                                            (localStorage.getItem('primaryRole') === 'Agent' && leads[0].agentId === localStorage.getItem('relatedAgentC')) ||
                                            (localStorage.getItem('primaryRole') !== 'Agent')
                                        ) && (
                                                <Grid item xs={1} md={1} style={{ display: 'flex', justifyContent: 'end' }} onClick={() => navigate(d.callToAction)}>
                                                    {d.callToAction !== "" && <img src={EDIT_ICON} className={classes.editIcon} />}
                                                </Grid>
                                            )}
                                        {d.title === 'KYC done' && (
                                            (leads[0].agentId === localStorage.getItem('relatedAgentC') && localStorage.getItem('primaryRole') === 'Agent') ||
                                            (localStorage.getItem('primaryRole') !== 'Agent')
                                        ) && (
                                                <Grid item xs={1} md={1} style={{ display: 'flex', justifyContent: 'end' }} onClick={() => navigate(d.callToAction)}>
                                                    {d.callToAction !== "" && <img src={EDIT_ICON} className={classes.editIcon} />}
                                                </Grid>
                                            )}
                                    </Grid>

                                case 'banner':
                                    return <Grid key={i} container spacing={0}>
                                        {d.date === null && <Grid item xs={12} md={12} style={{ height: '95px', backgroundColor: 'orange', color: 'white', borderRadius: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '26px' }}>
                                            {d.label[0]}
                                        </Grid>}

                                        {d.date !== null && <Grid item xs={12} md={12} style={{ height: '95px', backgroundColor: '#61D77F', color: 'white', borderRadius: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '26px' }}>
                                            <img src={d.icon} style={{ marginLeft: '-12px' }} /> {d.label[1]}
                                        </Grid>}
                                    </Grid>
                            }
                        })}

                        {loading === true && details.length === 0 && [1, 2, 3, 4, 5, 6, 7, 8].map((_d, index) =>
                            <Skeleton key={index} variant="rectangular" width={'100%'} height={50} style={{ marginTop: '16px', borderRadius: '12px' }} />
                        )}
                    </Grid>


                    <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                        <Grid item xs={6} md={6} style={{ display: 'flex', alignContent: 'start' }}>
                            <Typography variant='body1' className={classes.taskTitle}>Tasks</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button disableElevation variant="contained" disabled={leads.length === 0 ? true : false} color='primary' size='small' style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }} onClick={() => {
                                let params = `agentId=${locals.agentId}&&name=${leads[0].name}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                                const encodedText = base54Encode(params)
                                navigate(`/view/new-task?params=${encodedText}`)
                            }}
                                startIcon={<AddIcon />}>Create Task</Button>
                        </Grid>
                    </Grid>
                    {loading === false && tasks.length === 0 && <EmptyState title={'No Tasks found'} description={response.msg === "" ? `We could not find any tasks matching your records.` : response.msg} buttonText={null} url={null} />}

                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
                            <TaskStepper tasks={tasks} type={'detail'} />
                        </Grid>

                    </Grid>

                </Box>
            </div>
        </>
    );
};

export default LeadDetail;
