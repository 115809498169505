import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { DataProvider } from 'context/DataContext';
import { Paper, CssBaseline } from '@material-ui/core';
import AOS from 'aos';
import theme from './theme';
import AppRoutes from './Routes';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'aos/dist/aos.css';
import LocationCollector from 'helpers/location/location.collector';

const App = () => {
	AOS.init({
		once: true,
		delay: 50,
		duration: 500,
		easing: 'ease-in-out',
	});


	return (
		<>
			<LocationCollector />
			<ThemeProvider theme={theme}>
				<DataProvider>
					<CssBaseline />
					<Paper>
						<Router>
							<AppRoutes />
						</Router>
					</Paper>
				</DataProvider>
			</ThemeProvider>
		</>
	);
}

export default App;