import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        position: 'relative',
        overflowY: 'auto'
    },
    pagePaddingTop: {
        paddingTop: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(6),
        },
    },
    sectionNoPaddingTop: {
        paddingTop: 0,
    },
    shape: {
        backgroundColor: 'white',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        backgroundSize: 'cover',
        borderBottom: 'none'
    }
}));

export default useStyles