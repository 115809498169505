import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useStyles from './styles'
import { placeholders } from 'helpers/options/placeholders';
import Skeleton from '@mui/material/Skeleton';
import EmptyState from 'views/EmptyState';
import axiosInstance from 'services/axiosInstance';
import TaskStepper from 'views/LeadDetail/TaskStepper';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader/Loader';

const Tasks = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [tasks, setTasks] = useState([])
    const [leads, setLeads] = useState([])
    const [response, setResponse] = useState({ msg: "", status: "" })
    const [selectedOption, setSelectedOption] = useState(null);
    const [option, setOption] = useState(null)
    const [type, setType] = useState("ALL")

    useEffect(() => {
        fetchTasks()
        fetchLeads()
    }, [option])

    const fetchTasks = async () => {
        try {
            setLoading(true)
            const agentId = localStorage.getItem('relatedAgentC')
            const response = await axiosInstance.get(`tasks?type=${type}&agentId=${agentId}&leadId=${option}`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                setTasks(records)
                setResponse({ status: '', msg: records.length === 0 ? `We could not find any tasks matching your records.` : '' })
            } else {
                setResponse({ status: "error", msg: response.data.headers.status_message })
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
            if (error.headers.status_code === 401) {
                localStorage.clear()
                navigate("/login")
            }
        }
    }

    const fetchLeads = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`leads?agentId=${localStorage.getItem('relatedAgentC')}&status=null&search=null&&offset=0&limit=100`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                setLeads(records)
            } else {
                setResponse({ status: 'error', msg: response.data.headers.status_message })
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
            setResponse({ status: "error", msg: error.headers.status_message })
        }
    }

    return (
        <>
            <div className={classes.root}>
                {loading && <Loader />}
                <div className={classes.shape}>
                    <Box component="main" sx={{ p: 3, mb: 1, position: 'relative', width: '100%' }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}
                            direction='row'>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                data-aos={'fade-up'}>
                                <Typography variant='h4' style={{ fontWeight: 600, fontSize: '22px', color: 'white' }}>All Tasks</Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                data-aos={'fade-up'}>
                                <Autocomplete
                                    fullWidth
                                    options={leads}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedOption}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setOption(newValue.id);
                                            setType("QUERY")
                                        } else {
                                            setOption(null);
                                            setType("ALL")
                                        }
                                        setSelectedOption(newValue);
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ backgroundColor: '#F3F2F1' }}>{children}</Paper>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            variant="outlined"
                                            placeholder="Filter Tasks by Client"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <FilterAltIcon style={{ marginRight: '8px', color: '#9E9E9D', fontSize: '18px' }} />
                                                ),
                                            }}
                                            style={{
                                                backgroundColor: '#F3F2F1',
                                                fontSize: '12px',
                                                '& .MuiOutlinedInputNotchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                borderRadius: '12px'
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>

                <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>

                    {loading === false && <TaskStepper tasks={tasks} type={'main'} />}

                    {loading === false && tasks.length === 0 && <EmptyState title={'No Tasks found'} description={response.msg === "" ? `We could not find any notices matching your records.` : response.msg} buttonText={null} url={null} />}

                    {loading === true && placeholders.map((_d, index) =>
                        <Skeleton key={index} animation="wave" variant="rectangular" width={'100%'} height={90} style={{ marginTop: '12px', borderRadius: '8px' }} />
                    )}
                </Box>
            </div>
        </>
    );
};

export default Tasks;
