import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Button, FormControl, TextField, MenuItem, FormLabel } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import useStyles from './styles';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import axiosInstance from 'services/axiosInstance';
import dayjs from 'dayjs';
import { base54Decode, base54Encode } from 'helpers/base54Utils';
import { Box } from '@mui/material';
import Loader from 'components/Loader/Loader';

const HiddenInput = styled('input')({
    display: 'none',
});

const kycData = [{
    answer_type: 'dropdown',
    question: 'Customer Type',
    tkey: 'customerType',
    is_required: true,
    show: true,
    helperText: '',
    answers: [{
        tkey: '1',
        answer: 'Individual'
    }, {
        tkey: '2',
        answer: 'Distributor'
    }, {
        tkey: '3',
        answer: 'Dealer'
    }, {
        tkey: '4',
        answer: 'Project'
    }, {
        tkey: '5',
        answer: 'Partners'
    }, {
        tkey: "6",
        answer: 'R & D Test Clients'
    }, {
        tkey: '7',
        answer: 'Through Partner'
    }]
}, {
    answer_type: 'dropdown',
    question: 'Select Partner Customer',
    tkey: 'partnerCustomer',
    is_required: false,
    show: false,
    helperText: '',
    answers: []
}, {
    answer_type: 'number',
    question: 'National ID',
    tkey: 'idNumber',
    is_required: true,
    show: true,
    helperText: ''
},
{
    answer_type: 'date',
    question: 'Date of birth',
    tkey: 'dateOfBirth',
    is_required: true,
    show: true,
    helperText: ''
}]

const KYC = () => {
    const [locals, setLocals] = useState({ agentId: "", leadId: "", accountRef: "", mobilePhone: "" })
    const [selectedPhoto, setSelectedPhoto] = useState({ file: null, preview: null });
    const [selectedFileFront, setSelectedFileFront] = useState({ file: null, preview: null })
    const [selectedFileBack, setSelectedFileBack] = useState({ file: null, preview: null })
    const [response, setResponse] = useState({ status: "", msg: "" })
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({});
    const location = useLocation()
    const fileInputRef = useRef(null);
    const fileInputBackRef = useRef(null)
    const fileInputPhotoRef = useRef(null)
    const [data, setData] = useState(kycData)
    const [kycId, setKycId] = useState(null)

    useEffect(() => {
        setData(kycData)
        const queryParams = new URLSearchParams(location.search);
        const params = queryParams.get('params')
        const decodedText = base54Decode(params)
        const values = decodedText
            .split("&&")
            .reduce((acc, pair) => {
                const [key, value] = pair.replace(/\0/g, '').split("=").map(str => str.trim());
                if (key) acc[key] = value;
                return acc;
            }, {});

        const { leadId, accountRef, mobilePhone, agentId } = values;
        setLocals({ agentId: agentId, leadId: leadId, accountRef: accountRef, mobilePhone: mobilePhone })
        fetchPartners()
        fetchKycInformation(leadId)
    }, [])

    const fetchKycInformation = async (leadId) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`kyc?leadId=${leadId}`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                if (records.length > 0) {
                    const itemData = records[0]
                    Object.entries(itemData).forEach(([key, value]) => {
                        if (value !== null) {
                            if (key === 'dateOfBirth') {
                                setFormValues((prevValues) => ({ ...prevValues, [key]: dayjs(value) }));
                            } else {
                                if (key === "id") {
                                    setKycId(value)
                                } else {
                                    setFormValues((prevValues) => ({ ...prevValues, [key]: value }));
                                }
                            }
                        }
                    });
                }
            } else {
                setResponse({ status: 'error', msg: 'Request failed. Please try again' })
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                setResponse({ status: "error", msg: error.headers.status_message })
            } else {
                setResponse({ status: "error", msg: 'Sorry Something Went wrong Please try again' })
            }
        }
    }


    const fetchPartners = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`partners`);
            setLoading(false)
            if (response.status === 200) {
                let records = response.data.body
                data[1].answers = records
                setData(data)
            } else {
                setResponse({ status: 'error', msg: 'Request failed. Please try again' })
            }
        } catch (error) {
            setLoading(false)
            if (error.response) {
                setResponse({ status: "error", msg: error.headers.status_message })
            } else {
                setResponse({ status: "error", msg: 'Sorry Something Went wrong Please try again' })
            }
        }
    }

    const handleInputChange = (event, tkey) => {
        const { value } = event.target;
        if (tkey === 'customerType') {
            if (value === '7') {
                data[1].show = true
                data[1].is_required = true
                setData(data)
            } else {
                data[1].show = false
                data[1].false = true
                setData(data)
            }
        }
        setFormValues((prevValues) => ({ ...prevValues, [tkey]: value }));
    }

    const handleInputChange2 = (event, tkey) => {
        setFormValues((prevValues) => {
            if (!event) {
                const { [tkey]: _, ...rest } = prevValues;
                return rest;
            }
            return { ...prevValues, [tkey]: dayjs(event) };
        });
    }


    const handleFileInputChange = () => {
        const file = fileInputRef.current.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFileFront({ file: file, preview: reader.result })
            };
            reader.readAsDataURL(file);
        }
    }

    const handleFileInputChangeBack = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFileBack({ file: file, preview: reader.result })
            };
            reader.readAsDataURL(file);
        }
    }

    const handleFileInputChangePhoto = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedPhoto({ file: file, preview: reader.result })
            };
            reader.readAsDataURL(file);
        }
    }

    const handleRemoveButtonClick = () => {
        setSelectedFileFront({ file: null, preview: null });
    }

    const handleRemoveButtonClickBack = () => {
        setSelectedFileBack({ file: null, preview: null });
    }

    const handleRemoveButtonClickPhoto = () => {
        setSelectedPhoto({ file: null, preview: null })
    }

    const handleSubmit = async () => {
        if (kycId === null) {
            kycCreateRequest()
        } else {
            kycUpdateRequest()
        }
    }

    const kycCreateRequest = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("leadId", locals.leadId)
        formData.append("lat", localStorage.getItem('lat'))
        formData.append("lng", localStorage.getItem('lng'))
        if (selectedFileFront.file !== null) {
            formData.append('frontIdImage', selectedFileFront.file);
        }
        if (selectedFileBack.file !== null) {
            formData.append('backIdImage', selectedFileBack.file);
        }
        if (selectedPhoto.file !== null) {
            formData.append('passportPhoto', selectedPhoto.file);
        }
        formData.append('mobilePhone', locals.mobilePhone);
        formData.append('idNumber', formValues.idNumber);
        formData.append('dateOfBirth', formValues.dateOfBirth);
        formData.append('customerType', formValues.customerType)
        if (formValues.partnerCustomer !== null && formValues.customerType === '7') {
            formData.append('partnerCustomer', formValues.partnerCustomer)
        }

        try {
            const response = await axiosInstance.post(`kyc`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false)
            if (response.status === 200) {
                setResponse({ msg: response.data.headers.status_message, status: 'success' })
                setTimeout(() => {
                    let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                    const encodedText = base54Encode(params)
                    navigate(`/view/lead-detail?params=${encodedText}`)
                }, 2000)
            } else {
                setResponse({ msg: response.data.headers.status_message, status: 'error' })
            }

        } catch (error) {
            setLoading(false)
            setResponse({ msg: error.headers.status_message, status: 'error' })
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const kycUpdateRequest = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("leadId", locals.leadId)
        formData.append("lat", localStorage.getItem('lat'))
        formData.append("lng", localStorage.getItem('lng'))
        if (selectedFileFront.file !== null) {
            formData.append('frontIdImage', selectedFileFront.file);
        }
        if (selectedFileBack.file !== null) {
            formData.append('backIdImage', selectedFileBack.file);
        }
        if (selectedPhoto.file !== null) {
            formData.append('passportPhoto', selectedPhoto.file);
        }
        formData.append('mobilePhone', locals.mobilePhone);
        formData.append('idNumber', formValues.idNumber);
        formData.append('dateOfBirth', formValues.dateOfBirth);
        formData.append('customerType', formValues.customerType)
        if (formValues.partnerCustomer !== null && formValues.customerType === '7') {
            formData.append('partnerCustomer', formValues.partnerCustomer)
        }

        try {
            const response = await axiosInstance.put(`kyc`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false)
            if (response.status === 200) {
                setResponse({ msg: response.data.headers.status_message, status: 'success' })
                setTimeout(() => {
                    let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                    const encodedText = base54Encode(params)
                    navigate(`/view/lead-detail?params=${encodedText}`)
                }, 2000)
            } else {
                setResponse({ msg: response.data.headers.status_message, status: 'error' })
            }

        } catch (error) {
            setLoading(false)
            setResponse({ msg: error.headers.status_message, status: 'error' })
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={classes.root}>
            {loading && <Loader />}
            <div className={classes.shape}>
                <Box component="main" sx={{ p: 3, mb: 0, mt: 0, position: 'relative', width: '100%' }}>
                    <Grid
                        fullWidth
                        container
                        justifyContent="space-between"
                        spacing={2}
                        direction='row'>
                        <Grid
                            item
                            xs={4}
                            onClick={() => {
                                let params = `agentId=${locals.agentId}&&leadId=${locals.leadId}&&accountRef=${locals.accountRef}&&mobilePhone=${locals.mobilePhone}`
                                const encodedText = base54Encode(params)
                                navigate(`/view/lead-detail?params=${encodedText}`)
                            }}
                            md={6}
                            data-aos={'fade-up'}
                            style={{ display: 'flex', color: 'white', alignItems: 'center', flexDirection: 'row' }}>
                            <ArrowBackIosIcon className={classes.backArrow} /> <Typography variant='body1' className={classes.backArrow}>Back</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={8}
                            md={6}
                            data-aos={'fade-up'}>
                            <Typography variant='h6' className={classes.mainTitle}>KYC</Typography>
                        </Grid>

                    </Grid>
                </Box>
            </div>
            <Box component="main" sx={{ p: 3, mb: 2, position: 'relative', }}>
                <Grid container spacing={0}>
                    {response.status && <Grid spacing={3} container>
                        <Grid item xs={12} md={12}>
                            <Alert severity={response.status} style={{ marginBottom: '6px', fontSize: '12px' }}>{response.msg}</Alert>
                        </Grid>
                    </Grid>}
                    {data.map((field, index) => {
                        switch (field.answer_type) {
                            case 'dropdown':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey} style={{ display: field.show === true ? 'block' : 'none' }}>
                                        {field.tkey === 'productInterested' && loading === true && <LinearProgress style={{ height: '3px', marginBottom: '-6px' }} />}
                                        <FormControl fullWidth component="fieldset">
                                            <TextField
                                                fullWidth
                                                id={field.tkey}
                                                select
                                                required
                                                size="medium"
                                                label={field.question}
                                                variant='outlined'
                                                margin='normal'
                                                helperText={field.helperText}
                                                value={formValues[field.tkey] || ''}
                                                onChange={e => handleInputChange(e, field.tkey)}>
                                                <MenuItem disabled id="" key={index} value="" selected={false}>Select Your Option</MenuItem>
                                                {field.answers.map((option, index) => (
                                                    <MenuItem id={option.tkey} key={index} value={option.tkey}>
                                                        {option.answer}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                );
                            case 'number':
                                return (
                                    <Grid item xs={12} md={12} key={field.tkey}>
                                        <TextField
                                            margin="normal"
                                            required
                                            size="medium"
                                            fullWidth
                                            label={field.question}
                                            variant='outlined'
                                            value={formValues[field.tkey] || ''}
                                            onChange={e => handleInputChange(e, field.tkey)}
                                            name={field.tkey}
                                            type="number"
                                            helperText={field.helperText}
                                            id={field.tkey}
                                        />
                                    </Grid>
                                );
                            case 'date':
                                return (
                                    <Grid alignItems="center" container spacing={2} mt={1} key={field.tkey} style={{ marginTop: index === 0 ? '0px' : '8px' }}>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel id="_animal_label" className='label1'>{field.question}<sup>*</sup></FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={12} key={field.tkey}>
                                            <FormControl fullWidth component="fieldset" className={classes.datePickerRoot}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        fullWidth
                                                        localeText={{ toolbarTitle: 'Select Date of Birth' }}
                                                        inputFormat="MM/DD/YYYY"
                                                        disableFuture={true}
                                                        value={formValues[field.tkey] || ''}
                                                        onChange={e => handleInputChange2(e, field.tkey)}
                                                        slotProps={{
                                                            fieldSeparator: { children: 'to' },
                                                            textField: {
                                                                variant: 'outlined',
                                                                error: false,
                                                                helperText: `${field.helperText}`
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )
                            default:
                                return null;
                        }
                    })}

                    <Grid item xs={12} md={12} style={{ marginTop: '16px' }}>
                        <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 400, color: '#141414' }}>Upload National ID</Typography>
                        <Typography variant='body1' style={{ marginTop: '6px', fontSize: '16px', fontWeight: 400, color: '#90909D' }}>Upload both the front and the back sides</Typography>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: '13px' }}>

                        {!selectedFileFront.preview && <><Button variant="outlined" color='primary' startIcon={<FileUploadOutlinedIcon />} onClick={() => fileInputRef.current.click()}>
                            Upload
                        </Button>
                            <HiddenInput
                                id="fileInput"
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={handleFileInputChange}
                            />
                        </>
                        }

                    </Grid>

                    {selectedFileFront.preview && (
                        <Grid item xs={12} md={6} style={{ marginTop: '12px' }}>
                            <img src={selectedFileFront.preview} alt="Preview" style={{ width: '100%', height: 'auto', border: '1px dashed #07B282', padding: '10px' }} />

                        </Grid>
                    )}

                    <Grid container spacing={1}>
                        {!selectedFileBack.preview && selectedFileFront.preview && <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'start', marginTop: '20px' }}>
                            <Button variant="outlined" color='primary' startIcon={<FileUploadOutlinedIcon />} onClick={() => fileInputBackRef.current.click()}>
                                Other Side
                            </Button>
                            <HiddenInput
                                id="fileInputBack"
                                type="file"
                                accept="image/*"
                                ref={fileInputBackRef}
                                onChange={handleFileInputChangeBack}
                            />
                        </Grid>
                        }
                        {!selectedFileBack.preview && selectedFileFront.preview && <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                            <Button variant="outlined" color='secondary' startIcon={<DeleteForeverIcon />} onClick={handleRemoveButtonClick}>
                                Remove
                            </Button>
                        </Grid>
                        }

                        {selectedFileBack.preview && selectedFileFront.preview && <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'start', marginTop: '20px' }}>
                            <Button variant="outlined" color='secondary' startIcon={<DeleteForeverIcon />} onClick={handleRemoveButtonClick}>
                                Remove
                            </Button>
                        </Grid>
                        }
                    </Grid>

                    {selectedFileBack.preview && (
                        <Grid item xs={12} md={6} style={{ marginTop: '12px' }}>
                            <img src={selectedFileBack.preview} alt="Preview" style={{ width: '100%', height: 'auto', border: '1px dashed #07B282', padding: '10px' }} />

                        </Grid>
                    )}

                    {selectedFileBack.preview && <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'start', marginTop: '20px' }}>
                        <Button variant="outlined" color='secondary' startIcon={<DeleteForeverIcon />} onClick={handleRemoveButtonClickBack}>
                            Remove
                        </Button>
                    </Grid>
                    }

                    <Grid item xs={12} md={12} style={{ marginTop: '13px' }}>
                        <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 400, color: '#141414' }}>Upload Passport Photo</Typography>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: '13px' }}>

                        {!selectedPhoto.preview && <> <Button variant="outlined" color='primary' startIcon={<FileUploadOutlinedIcon />} onClick={() => fileInputPhotoRef.current.click()}>
                            Upload Photo
                        </Button>
                            <HiddenInput
                                id="fileInputPhoto"
                                type="file"
                                accept="image/*"
                                ref={fileInputPhotoRef}
                                onChange={handleFileInputChangePhoto}
                            />
                        </>
                        }
                        {selectedPhoto.preview && (
                            <Grid item xs={12} md={6} style={{ marginTop: '12px' }}>
                                <img src={selectedPhoto.preview} alt="Preview" style={{ width: '100%', height: 'auto', border: '1px dashed #07B282', padding: '10px' }} />
                            </Grid>
                        )}
                        {selectedPhoto.preview && <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
                            <Button variant="outlined" color='secondary' startIcon={<DeleteForeverIcon />} onClick={handleRemoveButtonClickPhoto}>
                                Remove
                            </Button>
                        </Grid>
                        }
                    </Grid>

                    <Grid item xs={12} md={12}>
                        {loading === false && <Button variant="contained" fullWidth color='primary' onClick={handleSubmit} style={{ marginTop: '25px', color: 'white' }}
                            disabled={
                                Object.keys(formValues).length >= data.filter(dt => dt.is_required === true).length ? false : true
                            }>
                            {kycId !== null ? 'Update' : 'Submit'}</Button>}

                        {loading && <LoadingButton fullWidth size='large' loading variant="outlined" style={{ marginTop: '25px' }}>
                            Submit
                        </LoadingButton>}
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
};

export default KYC;
