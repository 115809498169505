import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import useStyles from './styles';
import NO_DATA from 'assets/images/no_data.svg'

const EmptyState = ({ title, description, buttonText, url }) => {
    const classes = useStyles();
    const navigate = useNavigate()

    const handleClick = (e, url) => {
        e.preventDefault()
        navigate(url)
    };

    return (
        <div className={classes.root}>
            <Section className={classes.section}>
                <div className={classes.formContainer}>
                    <Grid container>
                        <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={NO_DATA} height={100} width={100} />
                        </Grid>
                    </Grid>
                    <SectionHeader
                        title={''}
                        label={title}
                        subtitle={
                            <span>
                                {description}
                            </span>
                        }
                        subtitleProps={{
                            variant: 'body1',
                        }}
                        labelProps={{
                            color: 'secondary',
                            className: classes.label,
                            variant: 'h5',
                        }}
                        ctaGroup={[

                            buttonText !== null && <Button
                                disableElevation
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleClick(e, url)}>
                                {buttonText}
                            </Button>,
                        ]}
                        disableGutter
                    />
                </div>
            </Section>
        </div>
    );
};

export default EmptyState;
